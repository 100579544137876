import React from 'react';
import { Grid, Paper } from '@mui/material';
import Copyright from 'src/components/Copyright/Copyright';
import ExternalSignupForm from 'src/components/StudentSignup/ExternalSignupForm';
import SignupForm from '../../components/StudentSignup/SignupForm';
import { UoMBlue } from '../../app/color';

type Props = {
  email?: string;
  external?: boolean;
};

function SignupPage({ email, external }: Props) {
  return (
    <Grid
      container
      component="main"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: UoMBlue,
        minHeight: '100vh',
        paddingTop: '8px',
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={7}
        component={Paper}
        elevation={5}
        square
        sx={{
          position: 'relative',
        }}
      >
        <div className="register-form" style={{ width: '100%' }}>
          {external && email ? <ExternalSignupForm email={email} /> : <SignupForm />}
        </div>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Grid>
  );
}

export default SignupPage;
