import { Alert, Snackbar, Stack, Typography } from '@mui/material';
import React from 'react';

interface ISuccessMessageProps {
  content: string;
}

function SuccessMessage({ content }: ISuccessMessageProps) {
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Alert severity="success">{content}</Alert>
    </Stack>
  );
}

export default SuccessMessage;
