import { Button, Link, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import '../../Register/Register.scss';
import { UoMBlue } from 'src/app/color';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import * as Yup from 'yup';
import { forgottenAdmin } from 'src/utils/adminApi';
import UniConnectEDlogo from '../../Logo/UniConnectEDlogo';
import WarningMessage from '../../Login/components/WarningMessage/WarningMessage';
import SuccessMessage from '../../Register/SuccessMessage/SuccessMessage';
import LoadingMessage from '../Loading/LoadingMessage';

function AdminForgottenPassword(this: any) {
  const navigate = useNavigate();
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const [loading, setLoading] = useState({
    shown: false,
    message: '',
  });
  const validationSchema = Yup.object({
    email: Yup
      .string()
      .trim()
      .email('Enter a valid email')
      .required('Email is required'),
  });
  const submitForm = useCallback(async (value: any) => {
    try {
      localStorage.setItem('email', value.email);
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Loading ... ',
      });
      const response = await forgottenAdmin(
        value.email,
      );
      if (response.status === StatusCodes.OK) {
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Find your Admin account. Waiting...',
        });
        setTimeout(() => {
          navigate('/admin/check-email');
        }, 3000);
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: 'Oops! Something went wrong.',
          });
        }
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      email: '',
      role: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
  });
  const roleHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('role', event.target.value);
  };
  return (
    <div className="background" style={{ backgroundColor: UoMBlue, height: '100vh' }}>
      {/* div for school register form, can copy to school profile overview page */}
      <div className="register-form" style={{ height: '100vh' }}>
        <form noValidate className="form" onSubmit={formik.handleSubmit}>
          <div className="logobox">
            <UniConnectEDlogo />
          </div>
          <h1 className="title" style={{ color: UoMBlue }}>Admin Forgotten Password</h1>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Contact Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div style={{ textAlign: 'center' }}>
            {warning.shown ? <WarningMessage content={warning.message} /> : null}
            {success.shown ? <SuccessMessage content={success.message} /> : null}
            {loading.shown ? <LoadingMessage content={loading.message} /> : null}
            <Button
              className="button"
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
            <br />
            <Link href="/login" variant="body2">
              Back to Student / Partner Sign in / Sign up
            </Link>
            <br />
            <Link href="/admin/login" variant="body2">
              Back to Admin Sign in
            </Link>
          </div>

        </form>
      </div>
    </div>
  );
}

export default AdminForgottenPassword;
