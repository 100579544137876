import { Button, Link } from '@mui/material';
import React, { useEffect } from 'react';
import '../../Register/Register.scss';
import { UoMBlue } from 'src/app/color';
import { useNavigate } from 'react-router-dom';
import UniConnectEDlogo from '../../Logo/UniConnectEDlogo';

function FailVerifyExternalRegistration() {
  const navigate = useNavigate();
  localStorage.clear();
  return (
    <div className="background" style={{ backgroundColor: UoMBlue, height: '100vh' }}>
      <div className="register-form" style={{ height: '100vh' }}>
        <div className="form" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="logobox">
            <UniConnectEDlogo />
          </div>
          <h1 className="title" style={{ color: UoMBlue }}>Link is invalid</h1>
          <br />
          <p>
            Please double check the link and copy it in the browser.
          </p>
          <br />
          <p>
            After 72 hours, the link will be expired.
            <br />
            If the link is expired, please
            {' '}
            <Link href="mailto:UniConnectED-Team@unimelb.edu.au">contact us</Link>
            {' '}
            to provide a new registration link.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FailVerifyExternalRegistration;
