import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputTextField from 'src/components/Form/InputTextField';
import { contactMail } from 'src/utils/globalApi';
import { StatusCodes } from 'http-status-codes';
import { INTERNAL_SERVER_ERROR } from 'src/common/constants/ErrorMessages';
import WarningMessage from 'src/components/Login/components/WarningMessage/WarningMessage';
import SuccessMessage from 'src/components/Register/SuccessMessage/SuccessMessage';
import LoadingMessage from 'src/components/ForgottenPassword/Loading/LoadingMessage';

const validationSchema = Yup.object({
  firstName: Yup
    .string()
    .required('First Name is required.'),
  lastName: Yup
    .string()
    .required('Last Name is required.'),
  email: Yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is required'),
  subject: Yup
    .string().required('Subject is required field.'),
  message: Yup
    .string().required('Message is required field.'),
});

function Contact() {
  const navigate = useNavigate();
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const [loading, setLoading] = useState({
    shown: false,
    message: '',
  });

  const submitForm = useCallback(async (value: any) => {
    try {
      const jsonObj = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        subject: value.subject,
        message: value.message,
      };
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Sending support request ... ',
      });
      const response = await contactMail(jsonObj);
      if (response.status === StatusCodes.OK) {
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Your support request has been received.',
        });
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: INTERNAL_SERVER_ERROR,
          });
        }
        if (error.response.status === StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: error.response.data,
          });
        }
      } else {
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
  });

  return (
    <>
      <Grid xs={12}>
        <h1 style={{ color: 'black', fontSize: '60px', margin: '40px auto', width: 'fit-content' }}>
          Contact Us
        </h1>
      </Grid>
      <Grid container xs={12} sm={8} md={6} sx={{ margin: '24px auto' }}>
        <form noValidate className="form" onSubmit={formik.handleSubmit}>
          <InputTextField formik={formik} inputName="firstName" label="First Name" required />
          <InputTextField formik={formik} inputName="lastName" label="Last Name" required />
          <InputTextField formik={formik} inputName="email" label="Email Address" required />
          <InputTextField formik={formik} inputName="subject" label="Subject" required />
          <InputTextField multiline rows={8} maxRows={8} formik={formik} inputName="message" label="Message" required />
          <div style={{ textAlign: 'center' }}>
            {warning.shown ? <WarningMessage content={warning.message} /> : null}
            {success.shown ? <SuccessMessage content={success.message} /> : null}
            {loading.shown ? <LoadingMessage content={loading.message} /> : null}
            <Button
              className="button"
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </div>
        </form>
      </Grid>
    </>
  );
}

export default Contact;
