import * as React from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import Groups2Icon from '@mui/icons-material/Groups2';
import SchoolIcon from '@mui/icons-material/School';
import TableViewIcon from '@mui/icons-material/TableView';
import GradeIcon from '@mui/icons-material/Grade';

export const schoolMainListItem = [
  { name: 'Profile', link: 'profile', icon: <AccountBoxIcon /> },
  { name: 'Students', link: 'uomstudents', icon: <Groups2Icon /> },
  { name: 'Favourites', link: 'favourites', icon: <GradeIcon /> },
];

export const studentMainListItem = [
  { name: 'Profile', link: 'profile', icon: <AccountBoxIcon /> },
  { name: 'Schools List', link: 'schools-list', icon: <SchoolIcon /> },
];

export const adminMainListItem = [
  { name: 'Dashboard', link: 'admin/dashboard', icon: <TableViewIcon /> },
  { name: 'Add External Partner', link: 'admin/external-partner', icon: <SchoolIcon /> },
  { name: 'Add External Student', link: 'admin/external-student', icon: <AccountBoxIcon /> },
];
