const UoMBlue = '#000F46';
const UniConnectEDGreen = '#1B8996';
const blackDark = '#000000';
const blackLight = '#99A2AA';
const tealDark = '#3FA7C2';
const tealLight = '#7CBDCE';
const emeraldDark = '#59B17F';
const emeralLight = '#98D6C3';
const blueDark = '#4074B2';
const blueLight = '#ADCDF0';
const yellowDark = '#ECAE65';
const yellowLight = '#FFDCA2';
const orangeDark = '#E77052';
const orangeLight = '#FFA478';
const greenDark = '#A1D172';
const greenLight = '#DAE299';
const purpleDark = '#7283BF';
const purpleLight = '#B0BCE1';
const pinkDark = '#EE8889';
const pinkLight = '#FFBDCC';

export {
  UoMBlue,
  UniConnectEDGreen,
  blackDark,
  blackLight,
  tealDark,
  tealLight,
  emeraldDark,
  emeralLight,
  blueDark,
  blueLight,
  yellowDark,
  yellowLight,
  orangeDark,
  orangeLight,
  greenDark,
  greenLight,
  purpleDark,
  purpleLight,
  pinkDark,
  pinkLight,
};
