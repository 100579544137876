import React from 'react';
import Box from '@mui/material/Box';

function UniConnectEDlogo(props: any) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexGrow: 1,
      }}
    >
      <img
        alt="UniConnectED logo"
        src="/images/uniconnected_logo.svg"
        style={{
          width: '96px',
          top: '40px',
        }}
      />
    </Box>
  );
}

export default UniConnectEDlogo;
