import { Alert, CircularProgress, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';

interface ILoadingMessageProps {
  content: string;
}

function LoadingMessage({ content }: ILoadingMessageProps) {
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Alert severity="info" icon={false}>
        <div style={{ display: 'inline-block' }}>
          <CircularProgress size={14} />
          {' '}
          {content}
        </div>
      </Alert>
    </Stack>
  );
}

export default LoadingMessage;
