import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import LoginForm from 'src/components/Login/components/LoginForm/LoginForm';

function AdminLoginPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: '100%',
        height: '100vh',
        justifyContent: 'center',
        p: 4,
      }}
    >
      <Box>
        <Box
          component="img"
          alt="The University of Melbourne logo"
          src="/images/uniconnected_logo.svg"
          sx={{
            width: '96px',
          }}
        />
      </Box>
      <Typography variant="h1" sx={{ fontSize: 24, color: 'primary.main', mt: 1 }}>UniConnectED</Typography>
      <Typography variant="h2" sx={{ fontSize: 16, color: 'primary.main', mt: 2 }}>Admin Sign In</Typography>
      <LoginForm user="admin" />
    </Box>
  );
}

export default AdminLoginPage;
