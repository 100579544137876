import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText, Link,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { UoMBlue } from '../../app/color';
import InputTextField from '../Form/InputTextField';
import ImageUploadV2 from '../Register/component/ImageUploadV2';
import WarningMessage from '../Login/components/WarningMessage/WarningMessage';
import SuccessMessage from '../Register/SuccessMessage/SuccessMessage';
import SingleSelectField from '../Form/SingleSelectField';
import MultiSelectField from '../Form/MultiSelectField';
import { externalSignup } from '../../utils/studentApi';
import UniConnectEDlogo from '../Logo/UniConnectEDlogo';
import {
  EMAIL_EXISTS_ERROR,
  INTERNAL_SERVER_ERROR,
  USERNAME_PASSWORD_MISMATCH_ERROR,
} from '../../common/constants/ErrorMessages';
import {
  availableMatch,
  courseMatch,
  courseProgressMatch,
  courseSpecializationMatch,
  learningAreaMatch,
  locationsMatch, skillMatch,
} from '../../common/FieldsMatches/studentProfileFieldsMatch';
import LoadingMessage from '../ForgottenPassword/Loading/LoadingMessage';
import TermOfUse from '../TermOfUse/TermOfUse';

const validationSchema = Yup.object({
  firstName: Yup
    .string()
    .required('First Name is required.'),
  lastName: Yup
    .string()
    .required('Last Name is required.'),
  preferredName: Yup
    .string(),
  pronouns: Yup
    .string(),
  email: Yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is required'),
  course: Yup
    .string().required('Course is required field.'),
  courseSpecialization: Yup
    .string()
    .when('course', ([course], schema) => {
      if (course !== 'GECT' && course !== '') {
        return schema.required('Course Specialization is required field.');
      }
      return schema;
    }),
  courseProgress: Yup
    .string().required('Course Progress is required field.'),
  currentLocation: Yup
    .string().required('Current Location is required field.'),
  workWithChild: Yup
    .string().required('Please check one of the selection.'),
  firstAidCertificate: Yup
    .string().required('Please check one of the selection.'),
  skills: Yup
    .string().required('Please select at least one Skill'),
  location: Yup
    .string().required('Please select at least one Location'),
  learningArea: Yup
    .string().required('Please select at least one Learning Area'),
  available: Yup
    .string().required('Please select at least one Skill'),
  image: Yup
    .string().required('Please upload a profile image'),
  password: Yup
    .string()
    .required('Password is required')
    .min(8, 'Password should be of minimum 8 characters length'),
  checkpassword: Yup
    .string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match!")
    .required('Please repeat your password.'),
  termOfUse: Yup
    .boolean().isTrue('Please read and check the term of use.'),
});

type Props = {
  email: string;
};

function ExternalSignupForm({ email: defaultEmail }: Props) {
  const [base64Data, setbase64Data] = React.useState('');
  const navigate = useNavigate();
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const [loading, setLoading] = useState({
    shown: false,
    message: '',
  });
  const submitForm = useCallback(async (value: any) => {
    try {
      const jsonObj = {
        image: value.image,
        email: value.email,
        password: value.password,
        firstName: value.firstName,
        lastName: value.lastName,
        preferredName: value.preferredName,
        pronouns: value.pronouns,
        studentNumber: value.studentNumber,
        course: value.course,
        courseSpecialization: value.courseSpecialization,
        courseProgression: value.courseProgress,
        currentLocation: value.currentLocation,
        workWithChildren: value.workWithChild === 'Yes',
        firstAidCertificate: value.firstAidCertificate,
        otherSkillExperience: value.skills,
        locationOption: value.location,
        learningAreas: value.learningArea,
        available: value.available,
        isPublic: 'True',
      };
      // For courses with no specializations, do not send an empty value to the backend
      // Instead send back NA
      if (!value.courseSpecialization && Object.keys(courseSpecializationMatch[value.course]).length === 0) {
        jsonObj.courseSpecialization = 'NA';
      }
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Loading ... ',
      });
      const signupResponse = await externalSignup(
        jsonObj,
      );
      if (signupResponse.status === StatusCodes.OK) {
        localStorage.setItem('email', value.email);
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Your account is created.',
        });
        setTimeout(() => {
          navigate('/check-email');
        }, 3000);
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: INTERNAL_SERVER_ERROR,
          });
        }
        if (error.response.status === StatusCodes.CONFLICT) {
          setWarning({
            shown: true,
            message: EMAIL_EXISTS_ERROR,
          });
        }
        if (error.response.status === StatusCodes.METHOD_NOT_ALLOWED) {
          setWarning({
            shown: true,
            message: 'Your account is blocked. You cannot use this email to create account.',
          });
        }
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          setWarning({
            shown: true,
            message: USERNAME_PASSWORD_MISMATCH_ERROR,
          });
        }
        if (error.response.status === StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: error.response.data,
          });
        }
      } else {
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      preferredName: '',
      pronouns: '',
      studentNumber: '',
      email: defaultEmail,
      course: '',
      courseSpecialization: '',
      courseProgress: '',
      currentLocation: '',
      workWithChild: '',
      firstAidCertificate: '',
      skills: '',
      location: '',
      learningArea: '',
      available: '',
      image: '',
      password: '',
      checkpassword: '',
      termOfUse: false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
  });

  const callBack = (childdata:string) => {
    setbase64Data(childdata);
    formik.setFieldValue('image', childdata.toString());
  };

  const workWithChildHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('workWithChild', event.target.value);
  };

  const firstAidCertificateHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('firstAidCertificate', event.target.value);
  };

  return (
    <form noValidate className="form" onSubmit={formik.handleSubmit}>
      <div className="logobox">
        <UniConnectEDlogo />
      </div>
      <h1 className="title" style={{ color: UoMBlue }}>
        Student Registration
        <Link href="/resources" target="_self">
          <HelpOutlineOutlinedIcon fontSize="small" />
        </Link>
      </h1>

      <InputTextField formik={formik} inputName="firstName" label="First Name" required />
      <InputTextField formik={formik} inputName="lastName" label="Last Name" required />
      <InputTextField formik={formik} inputName="preferredName" label="Preferred Name" />
      <InputTextField formik={formik} inputName="pronouns" label="Pronouns" />
      <InputTextField formik={formik} inputName="email" label="Student Email" required initValue={defaultEmail} disabled />

      <div>
        <SingleSelectField
          required
          formik={formik}
          inputName="course"
          title="Course Name"
          selection={courseMatch}
          style={{ width: formik.values.course !== 'GECT' && formik.values.course !== '' ? '45%' : '100%' }}
          onSelect={(event, selected) => formik.setFieldValue('courseSpecialization', '')}
        />
        {formik.values.course !== 'GECT' && formik.values.course !== '' ? (
          <SingleSelectField
            required
            formik={formik}
            inputName="courseSpecialization"
            title="Course Specialization"
            style={{ width: '53%', float: 'right' }}
            selection={courseSpecializationMatch[formik.values.course] || {}}
          />
        )
          : null }
      </div>
      <SingleSelectField required formik={formik} inputName="courseProgress" title="Course Progression" selection={courseProgressMatch} />
      <SingleSelectField required formik={formik} inputName="currentLocation" title="Current Location" selection={locationsMatch} helpLink="https://www.vic.gov.au/regional-model-department-education" />

      <Box mt={3}>
        <FormControl onChange={firstAidCertificateHandle} error={formik.touched.firstAidCertificate && Boolean(formik.errors.firstAidCertificate)} style={{ width: '50%' }}>
          <FormLabel required id=" abuttons-group-label">First Aid Certificate </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            <FormControlLabel value="FA" control={<Radio />} label="First Aid" />
            <FormControlLabel value="FC" control={<Radio />} label="First Aid & CPR" />
            <FormControlLabel value="NO" control={<Radio />} label="No Certificate" />
          </RadioGroup>
        </FormControl>

        <FormControl onChange={workWithChildHandle} error={formik.touched.workWithChild && Boolean(formik.errors.workWithChild)} style={{ width: '50%' }}>
          <FormLabel required id=" abuttons-group-label">Working With Children Check </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Box>

      <MultiSelectField allowCustomOptions required formik={formik} inputName="skills" title="Skills" selection={skillMatch} />
      <MultiSelectField allowCustomOptions required formik={formik} inputName="location" title="Open To Work Locations" selection={locationsMatch} helpLink="https://www.vic.gov.au/regional-model-department-education" />
      <MultiSelectField allowCustomOptions required formik={formik} inputName="learningArea" title="Learning Area" selection={learningAreaMatch} />
      <MultiSelectField
        allowCustomOptions
        required
        formik={formik}
        inputName="available"
        title="Available For"
        selection={availableMatch}
        helpText="Only select Casual Relief Teaching if you have VIT registration"
      />

      <div style={{ width: '100%' }}>
        <h3>Upload Photo*</h3>
        <p>
          Hint: Upload a photo to personalise your student profile.
          <Tooltip title="Eg: Passport photo, avatar, picture of your pet">
            <InfoOutlinedIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '5px' }} />
          </Tooltip>
        </p>
        <ImageUploadV2 handelCallback={callBack} style={{ width: '100%' }} />
        <FormHelperText>{formik.touched.image && formik.errors.image}</FormHelperText>
      </div>
      <h3>Set Password*</h3>
      <InputTextField formik={formik} inputName="password" label="Password" type="password" />
      <InputTextField formik={formik} inputName="checkpassword" label="Repeat Password" type="password" />
      <TermOfUse formik={formik} type="student" />
      <div style={{ textAlign: 'center' }}>
        {warning.shown ? <WarningMessage content={warning.message} /> : null}
        {success.shown ? <SuccessMessage content={success.message} /> : null}
        {loading.shown ? <LoadingMessage content={loading.message} /> : null}
        <Button
          className="button"
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
        <br />
        <Link href="/login" variant="body2">
          Already have a student account? Back to Sign In
        </Link>
      </div>
    </form>
  );
}

export default ExternalSignupForm;
