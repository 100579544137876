import React from 'react';
import { Box, Button, Checkbox, FormHelperText, Grid, Link, Modal, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import partnerPDF from './UniConnectED - Partner Terms and Conditions.pdf';
import studentPDF from './UniConnectED - Student Terms and Conditions.pdf';

const style = {
  position: 'absolute' as 'absolute',
  top: { xs: '60%', sm: '53%', md: '50%', lg: '50%' },
  left: { xs: '55%', sm: '52%', md: '51%', lg: '51%' },
  height: { xs: '90%', sm: '90%' },
  maxHeight: '750px',
  maxWidth: '1173px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '7px',
  p: 4,
  overflowY: 'auto',
  overflowX: 'hidden',
  justifyContent: 'center',
};

type Props = {
  formik: any;
  type: string;
};

const downloadDoc = (type: string) => {
  const name = `UniConnectED - ${type === 'school' ? 'Partner' : 'Student'} Terms and Conditions.pdf`;
  const pdf = type === 'school' ? partnerPDF : studentPDF;
  fetch(pdf, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json', responseType: 'arraybuffer' },
  })
    .then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.click();
      });
    });
};

function TermOfUse({ formik, type }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleChange = (event: any) => {
    const { checked } = event.target;
    formik.setFieldValue('termOfUse', checked);
  };

  return (
    <div>
      <Typography variant="body1" component="span" align="center">
        <Checkbox onChange={handleChange} />
        I have read and agree to abide by the
        <Link href="#" onClick={handleOpen}> terms of use</Link>
        .
      </Typography>
      <FormHelperText>{formik.touched.termOfUse && formik.errors.termOfUse}</FormHelperText>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container sx={style} spacing={3} direction="column">
          <Button
            onClick={handleClose}
            sx={{
              position: 'absolute' as 'absolute',
              top: '0',
              pt: 0,
              height: '8%',
              width: '90%',
              zIndex: 1,
              display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' },
            }}
          >
            <ExpandMoreIcon />
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              position: 'absolute' as 'absolute',
              top: 0,
              pt: 3,
              pb: 3,
              pr: 5,
              pl: 5,
              right: '0%',
              zIndex: 1,
              display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
            }}
          >
            <CloseIcon />
          </Button>
          <Grid item xs={1}>
            <Typography
              variant="h1"
              sx={{ fontSize: 26, color: 'primary.main' }}
            >
              Terms of Use
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Box sx={{ fontSize: 16 }}>
              <Typography variant="h2" sx={{ fontSize: 22, margin: '10px 0' }}>
                <Link onClick={() => downloadDoc(type)} href="#">Download terms and conditions</Link>
              </Typography>
              <Typography variant="h2" sx={{ fontSize: 22, margin: '10px 0' }}>
                <Link href="https://students.unimelb.edu.au/careers/find-a-job/your-work-rights" target="_blank">Working rights in Australia</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default TermOfUse;
