import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, TextField, FormHelperText } from '@mui/material';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

type DropdownOptions = {
  key: string,
  label: string,
};

type JsonObject = {
  [key: string]: string;
};

type Props = {
  formik: any;
  inputName: string;
  title: string;
  selection: JsonObject;
  required?: boolean;
  initValue?: string;
  helpLink?: string;
  onSelect?: (event: any, selected: DropdownOptions | null) => void;
  style?: Object;
};

const defaultProps = {
  required: false,
  initValue: '',
};

function SingleSelectField({ formik, inputName, title, selection, required, initValue, helpLink, onSelect, style }: Props) {
  const handleChange = (event: any, selected: DropdownOptions | null) => {
    const selectedValue: string = selected === null ? '' : selected.key;
    formik.setFieldValue(inputName, selectedValue);
    if (onSelect) {
      onSelect(event, selected);
    }
  };
  const dropdownOptions = Object.keys(selection).map((key): DropdownOptions => (
    {
      key,
      label: selection[key],
    }
  ));

  useEffect(() => {
    formik.setFieldValue(inputName, initValue);
  }, [initValue]);

  const textAdornment = helpLink ? (
    <InputAdornment
      position="end"
      style={{
        float: 'right',
        top: '55%',
        position: 'absolute',
        right: '65px',
      }}
    >
      <Link href={helpLink} target="_blank">
        <HelpOutlineOutlinedIcon fontSize="small" />
      </Link>
    </InputAdornment>
  ) : null;

  return (
    <FormControl
      variant="outlined"
      style={{ width: '100%', margin: 1, ...style }}
    >
      <Autocomplete
        disablePortal
        id={inputName}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        options={dropdownOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={formik.touched[inputName] && Boolean(formik.errors[inputName])}
            label={title}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  { textAdornment }
                  { params.InputProps.endAdornment }
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue, { insideWords: true });
          const parts = parse(option.label, matches);
          return (
            <li {...props}>
              <div>
                {parts.map((part:any, index:any) => (
                  <span
                  // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        style={{ marginTop: '16px' }}
        value={dropdownOptions.find((option) => option.key === formik.values[inputName]) || { key: '', label: '' }}
        onChange={handleChange}
      />
      <FormHelperText>{formik.touched[inputName] && formik.errors[inputName]}</FormHelperText>
    </FormControl>
  );
}

SingleSelectField.defaultProps = defaultProps;
export default SingleSelectField;
