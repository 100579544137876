import { Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { UoMBlue } from 'src/app/color';

function Copyright(props: any) {
  return (
    <Grid item xs={12} sx={{ backgroundColor: UoMBlue, height: '8.5em', position: 'relative' }}>
      <Typography variant="body2" color="white" align="center" style={{ width: 'calc(100% - 240px)', margin: '40px 120px' }} {...props}>
        <Typography
          variant="body2"
          color="white"
          style={{
            margin: '14px 0',
          }}
          {...props}
        >
          <Link color="inherit" href="mailto:UniConnectED-Team@unimelb.edu.au">
            Need Help? Contact Us
          </Link>
        </Typography>
        {'Copyright © '}
        {'UniConnectED in association with '}
        <Link color="inherit" href="https://www.unimelb.edu.au/">
          The University of Melbourne
        </Link>
        {' '}
        {new Date().getFullYear()}
        .
      </Typography>
      <img
        alt="The University of Melbourne logo"
        src="/images/UoM_Logo.svg"
        style={{
          width: '100px',
          height: '100px',
          position: 'absolute',
          right: '20px',
          top: '20px',
        }}
      />
    </Grid>
  );
}
export default Copyright;
