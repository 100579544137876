import { Box, Button, Card, CardContent, CardMedia, Link, Typography } from '@mui/material';
import React from 'react';
import { blackDark, blackLight, UoMBlue } from 'src/app/color';
import HiringIcon from '../../icons/MTSI-Partner.png';

interface Props {
  image: string,
  info: Array<string>,
  showHiringBadge: boolean,
  showContactButton: boolean,
}

function ProfileCard({ image, info, showHiringBadge, showContactButton }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Card onClick={handleOpen}>
        <CardMedia
          component="img"
          src={image}
          style={{
            objectFit: 'contain',
            backgroundColor: 'white',
            height: '200px',
            marginTop: '24px',
          }}
        />
        <CardContent sx={{ backgroundColor: 'white', height: '250px', overflowY: 'auto', overflowX: 'hidden' }}>
          {showHiringBadge ? (
            <Typography variant="body2" sx={{ justifyContent: 'center' }}>
              <CardMedia
                component="img"
                src={HiringIcon}
                sx={{
                  objectFit: 'contain',
                  margin: '0 auto',
                  height: '60px',
                  width: '60px',
                }}
              />
            </Typography>
          ) : null}
          <Typography variant="body1" color={UoMBlue}>
            {info[0]}
          </Typography>
          <Typography variant="h6" color={blackDark}>
            {info[1]}
          </Typography>
          <Link color={blackLight} href={info[2]} target="__blank" sx={{ display: 'flex' }}>{info[2]}</Link>
          {showContactButton ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <Button
                onClick={(e) => {
                  window.location.href = `mailto:${info[3]}`;
                  e.preventDefault();
                }}
                variant="contained"
                color="primary"
                sx={{
                  mr: '16px',
                  marginBottom: '10px',
                }}
              >
                Contact
              </Button>
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}
export default ProfileCard;
