import _ from 'lodash';

export const checkInFilter = (filterContent:string[], infoContent:string) => {
  const lowerCaseFilter = filterContent.map((item:any) => item.toLowerCase());
  const lowerCaseInfo = infoContent.toLowerCase().split(', ');
  for (let i = 0; i < lowerCaseFilter.length; i += 1) {
    if (_.some(lowerCaseInfo, (info: string) => info.includes(lowerCaseFilter[i]))) {
      return true;
    }
  }
  return false;
};
