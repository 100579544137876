import { Box, Grid, Tab, Paper, TextField, Checkbox, Button, FormControlLabel, Link, Typography } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import React from 'react';
import LoginForm from './components/LoginForm/LoginForm';
import Copyright from '../Copyright/Copyright';

function Login() {
  const [tab, setTab] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Grid container component="main">
      <Grid
        item
        xs={false}
        sm={4}
        md={4}
        lg={7}
        sx={{
          backgroundImage: 'url(images/login_image.png)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: 'calc(100vh - 200px)',
        }}
      />
      <Grid item xs={12} sm={8} md={8} lg={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', maxWidth: '650px' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mb: 4,
              }}
            >
              <Box
                component="img"
                alt="UniConnectED logo"
                src="/images/uniconnected_logo.svg"
                sx={{
                  width: '96px',
                }}
              />
            </Box>
            <Typography className="beta" variant="h1" sx={{ fontSize: 24, color: 'primary.main', mt: 1, mb: 1, textAlign: 'center' }}>UniConnectED</Typography>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" centered>
                  <Tab label="Partner" value="1" />
                  <Tab label="Student" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ pr: 0, pl: 0 }}>
                <LoginForm user="partner" />
              </TabPanel>
              <TabPanel value="2" sx={{ pr: 0, pl: 0 }}>
                <LoginForm user="student" />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Grid>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </Grid>
  );
}

export default Login;
