import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import React, { useEffect } from 'react';
import { confirmEmailSchool } from 'src/utils/schoolsApi';
import { confirmEmailStudent } from 'src/utils/studentApi';
import { confirmEmailAdmin } from 'src/utils/adminApi';
import { StatusCodes } from 'http-status-codes';
import FailVerifyEmail from './FailVerifyEmail';
import ResetPassword from './ResetPassword';

async function fetchUserData() {
  if (window.location.search === '') {
    return '';
  }
  const param = window.location.search.split('=');
  const token = param[1];
  const path = window.location.pathname.split('/');
  if (path[1] === 'school') {
    const response = confirmEmailSchool(token.toString());
    return response;
  }
  if (path[1] === 'student') {
    const response = confirmEmailStudent(token.toString());
    return response;
  }
  if (path[1] === 'admin') {
    const response = confirmEmailAdmin(token.toString());
    return response;
  }
  return '';
}

function VerifyEmail() {
  const [status, setStatus] = React.useState('');
  const navigate = useNavigate();
  useEffect(() => {
    fetchUserData()
      .then((userData) => {
        if (userData === '') {
          navigate('/');
        }
        setStatus('Success');
      })
      .catch((error) => {
        if (error.response.status >= StatusCodes.BAD_REQUEST) {
          setStatus('Fail');
        }
      });
  }, [navigate]);
  return (
    <div>
      {status === 'Success' && <ResetPassword />}
      {status === 'Fail' && <FailVerifyEmail />}
    </div>
  );
}

export default VerifyEmail;
