import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = {
  formik: any;
  inputName: string;
  label: string;
  required?: boolean;
  type?: string;
  initValue?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
};

const defaultProps = {
  required: false,
  type: '',
  initValue: '',
};

function InputTextField({ formik, inputName, label, required, type, initValue, disabled, multiline, rows, maxRows }: Props) {
  const [defaultValue, setDefaultValue] = useState(initValue);
  const handleChange = (event: any) => {
    const { value } = event.target;
    formik.setFieldValue(inputName, value);
  };

  useEffect(() => {
    formik.setFieldValue(inputName, initValue);
    setDefaultValue(initValue);
  }, [initValue]);

  return (
    <TextField
      autoComplete="off"
      margin="normal"
      required={required}
      fullWidth
      id={inputName}
      label={label}
      name={inputName}
      onBlur={handleChange}
      error={formik.touched[inputName] && Boolean(formik.errors[inputName])}
      helperText={formik.touched[inputName] && formik.errors[inputName]}
      sx={{ marginBottom: '12px' }}
      type={type}
      defaultValue={defaultValue}
      key={initValue === null ? 'null' : defaultValue}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
    />
  );
}

InputTextField.defaultProps = defaultProps;
export default InputTextField;
