import axios from 'axios';

const baseUrl = process.env.REACT_APP_UNI_RECRUIT_API;

axios.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('accessToken')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (res: any) => res,
  (error: any) => {
    const status = error.response ? error.response.status : null;
    if (status === 406) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (status === 403) {
      window.location.replace('/forbidden');
    }
    return Promise.reject(error);
  },
);

export const getSchoolsInfo = () => axios({
  method: 'get',
  url: `${baseUrl}/schools`,
});

export const schoolLogin = (email: string, password: string) => axios({
  method: 'post',
  url: `${baseUrl}/login`,
  data: {
    email,
    password,
  },
});

export const verifySchoolStatus = (email: string, token: string) => axios({
  method: 'get',
  url: `${baseUrl}/schools/verify`,
  headers: { Authorization: `Bearer ${token}` },
  params: {
    email,
  },
});

export const externalSignup = (jsonObj:any) => axios.post(`${baseUrl}/schools/external-signup`, jsonObj);

export const signup = (jsonObj:any) => axios.post(`${baseUrl}/schools/signup`, jsonObj);

export const verifyRegistrationToken = (token: string) => axios.get(`${baseUrl}/schools/verify-registration-token?token=${token}`);

export const getProfile = (id:string, token: string) => axios.get(`${baseUrl}/schools/${id}`);

export const getStudentProfile = (id:string) => axios.get(`${baseUrl}/schools/student-profiles/${id}`);

export const emailCount = (id:string) => axios.post(`${baseUrl}/schools/email-count?id=${id}`);

export const editProfile = (id: string, jsonObj: any, token: string) => axios({
  method: 'put',
  url: `${baseUrl}/schools/${id}`,
  data: jsonObj,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const confirmEmailSchool = (token: string) => axios.get(`${baseUrl}/schools/confirm-email?token=${token}`);

export const resendEmailSchool = (email: string) => axios.post(`${baseUrl}/schools/resend-confirmation?email=${email}`);

export const forgottenSchool = (email: string) => axios({
  method: 'post',
  url: `${baseUrl}/schools/forget-password-email?email=${email}`,
});

export const resetPasswordSchool = (email:string, token: string, jsonObj: any) => axios({
  method: 'put',
  url: `${baseUrl}/schools/password/${email}/${token}`,
  data: jsonObj,
});

export const like = (studentId: string, schoolId: string) => axios({
  method: 'post',
  url: `${baseUrl}/schools/add?schoolUserId=${schoolId}&studentUserId=${studentId}`,
});

export const getLikeProfile = (id:string) => axios.get(`${baseUrl}/schools/${id}/liked-students`);

export const toggleHiring = (id: string) => axios({
  method: 'put',
  url: `${baseUrl}/schools/hiring/${id}`,
});

export const toggleDirectContact = (id: string) => axios({
  method: 'put',
  url: `${baseUrl}/schools/direct_contact/${id}`,
});

export const getResume = (id: string) => axios({
  method: 'get',
  url: `${baseUrl}/schools/student-profiles/${id}/resume`,
  responseType: 'arraybuffer',
});
