import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import React, { useEffect } from 'react';
import { verifyRegistrationToken } from 'src/utils/schoolsApi';
import { StatusCodes } from 'http-status-codes';
import RegisterExternalSchool from './ExternalRegister';
import FailVerifyExternalRegistration from '../ForgottenPassword/Verify/FailVerifyExternalRegistration';

async function fetchTokenData() {
  if (window.location.search === '') {
    return '';
  }

  const param = window.location.search.split('=');
  const token = param[1];

  const response = verifyRegistrationToken(token.toString());
  const { data } = await response;

  return data;
}

function VerifySignupLink() {
  const [status, setStatus] = React.useState('');
  const [email, setEmail] = React.useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchTokenData()
      .then((tokenEmail) => {
        if (tokenEmail === '') {
          navigate('/');
        }
        setStatus('Success');
        setEmail(tokenEmail);
      })
      .catch((error) => {
        if (error?.response?.status >= StatusCodes.BAD_REQUEST) {
          setStatus('Fail');
        }
        setStatus('Fail');
      });
  }, [navigate]);

  return (
    <div>
      {status === 'Success' && <RegisterExternalSchool email={email} />}
      {status === 'Fail' && <FailVerifyExternalRegistration />}
    </div>
  );
}

export default VerifySignupLink;
