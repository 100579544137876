import { Navigate, useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import React, { useEffect } from 'react';
import '../../Register/Register.scss';
import { UoMBlue } from 'src/app/color';
import UniConnectEDlogo from '../../Logo/UniConnectEDlogo';

function CheckEmail() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  useEffect(() => {
    if (localStorage.length === 0) {
      navigate('/');
    }
    setEmail(localStorage.getItem('email') || '');
  });
  return (
    <div className="background" style={{ backgroundColor: UoMBlue, height: '100vh' }}>
      <div className="register-form" style={{ height: '100vh' }}>
        <div className="form" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="logobox">
            <UniConnectEDlogo />
          </div>
          <h1 className="title" style={{ color: UoMBlue }}>Check your Email</h1>
          <p>
            An email with verification instructions was sent to
          </p>
          <br />
          <p>
            {email}
          </p>
          <br />
          <p>
            If you don't see the email in your inbox, remember to try your spam folder too.
            Once you verify your email address, you can get started with your contact email and password.
          </p>
          <br />
          <Link href="/admin/login" variant="body2">
            Back to Admin Sign in
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CheckEmail;
