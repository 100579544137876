import { Box, Button, Card, Checkbox, Container, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import '../../Register/Register';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { INTERNAL_SERVER_ERROR } from 'src/common/constants/ErrorMessages';
import { StatusCodes } from 'http-status-codes';
import ImageUploadV2 from 'src/components/Register/component/ImageUploadV2';
import { UoMBlue } from 'src/app/color';
import axios from 'axios';
import { getProfile, signup, editProfile } from '../../../utils/schoolsApi';
import WarningMessage from '../../Login/components/WarningMessage/WarningMessage';
import SuccessMessage from '../../Register/SuccessMessage/SuccessMessage';
import InputTextField from '../../Form/InputTextField';
import { locationsMatch, sectorMatch, yearMatch } from '../../../common/FieldsMatches/studentProfileFieldsMatch';
import SingleSelectField from '../../Form/SingleSelectField';
import MultiSelectField from '../../Form/MultiSelectField';

async function fetchUserData() {
  const response = getProfile(localStorage.getItem('userId') || '', localStorage.getItem('accessToken') || '');
  const { data } = await response;
  return data;
}

function SchoolEditForm() {
  const navigate = useNavigate();

  // USER STATE
  const [base64Data, setbase64Data] = React.useState('');
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const [values, setValues] = React.useState({
    schoolName: '',
    contactName: '',
    webAddress: '',
    image: '',
    yearLevel: '',
  });

  const validationSchema = Yup.object({
    webAddress: Yup
      .string()
      .url('Invalid website format. Example: http://www.google.com')
      .required('Website address is required.'),
    schoolName: Yup
      .string()
      .required('Partner name is required.'),
    contactName: Yup
      .string()
      .required('Contact name is required.'),
    location: Yup
      .string()
      .required('Location is required.'),
    sector: Yup
      .string()
      .required('Sector is required.'),
    yearLevel: Yup
      .string()
      .required('Year level is required.'),
  });

  const submitForm = useCallback(async (value: any) => {
    const id = localStorage.getItem('userId') || '';
    const token = localStorage.getItem('accessToken') || '';
    if (!id) {
      console.error('User ID not found in localStorage');
      return;
    }
    try {
      const jsonObj = {
        image: value.image ? value.image : values.image,
        location: value.location,
        sector: value.sector,
        webAddress: value.webAddress,
        yearLevels: value.yearLevel,
        schoolName: value.schoolName,
        contactName: value.contactName,
      };
      const response = await editProfile(id, jsonObj, token);
      if (response.status === StatusCodes.OK) {
        setWarning({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Your account been updated.',
        });
        // navigate('/');
        setTimeout(() => {
          navigate('/profile');
        }, 3000);
      }
      // navigate('/profile');
    } catch (error: any) {
      if (error.response) {
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: INTERNAL_SERVER_ERROR,
          });
        }
      } else {
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [values]);

  const formik = useFormik({
    initialValues: {
      image: '',
      webAddress: '',
      schoolName: '',
      contactName: '',
      location: '',
      sector: '',
      yearLevel: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
  });

  const callBack = (childdata:string) => {
    setbase64Data(childdata);
    formik.setFieldValue('image', childdata.toString());
  };

  // USE EFFECT
  useEffect(() => {
    fetchUserData()
      .then((userData) => {
        setValues({
          schoolName: userData.schoolName || '',
          contactName: userData.contactName || '',
          webAddress: userData.webAddress || '',
          image: userData.image || '',
          yearLevel: userData.yearLevels || '',
        });
        formik.setFieldValue('location', userData.location !== 'NA' ? userData.location : '');
        formik.setFieldValue('sector', userData.sector);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Container sx={{ mt: 15 }}>
      <div className="background">
        {/* div for school register form, can copy to school profile overview page */}
        <div className="register-form" style={{ width: '100%' }}>
          <form noValidate className="form" onSubmit={formik.handleSubmit}>
            <h1 className="title" style={{ color: UoMBlue }}>Edit Partner Profile</h1>
            <InputTextField formik={formik} inputName="schoolName" initValue={values.schoolName} label="Partner Name" />
            <InputTextField formik={formik} inputName="contactName" initValue={values.contactName} label="Contact Name" />
            <InputTextField formik={formik} inputName="webAddress" initValue={values.webAddress} label="Web Address" />
            <SingleSelectField required formik={formik} inputName="location" title="Location" selection={locationsMatch} helpLink="https://www.vic.gov.au/regional-model-department-education" />
            <SingleSelectField required formik={formik} inputName="sector" title="Sector" selection={sectorMatch} />
            <MultiSelectField required selectAll formik={formik} inputName="yearLevel" initValue={values.yearLevel} title="Year Levels" selection={yearMatch} />
            <div style={{ width: '100%' }}>
              <h3>Partner Logo / Picture</h3>
              {/* <ImageUploadV1 handelCallback={callBack} style={{ width: '100%' }} /> */}
              <ImageUploadV2 handelCallback={callBack} style={{ width: '100%' }} />
            </div>
            <div style={{ textAlign: 'center' }}>
              {warning.shown ? <WarningMessage content={warning.message} /> : null}
              {success.shown ? <SuccessMessage content={success.message} /> : null}
              <Button
                className="button"
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default SchoolEditForm;
