import React, { useEffect } from 'react';
import { Autocomplete, Checkbox, FormControl, FormHelperText, TextField, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { UoMBlue } from 'src/app/color';

type DropdownOptions = {
  custom: boolean,
  key: string,
  label: string,
};

type JsonObject = {
  [key: string]: string,
};

type Props = {
  formik: any;
  allowCustomOptions?: boolean;
  inputName: string;
  title: string;
  selection: JsonObject;
  required?: boolean;
  initValue?: string;
  helpLink?: string;
  helpText?: string;
  selectAll?: boolean
  maxSelection?: number;
};

const defaultProps = {
  required: false,
  initValue: '',
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions<DropdownOptions>();

function MultiSelectField({ formik, allowCustomOptions, inputName, title, selection, required, initValue, helpLink, helpText, maxSelection, selectAll }: Props) {
  const dropdownOptions = Object.keys(selection).map((key): DropdownOptions => (
    {
      custom: false,
      key,
      label: selection[key],
    }
  ));

  const [selectedValue, setDropdownOptions] = React.useState<DropdownOptions[]>([]);
  const allSelected = selectedValue.reduce((counter, selected) => (
    dropdownOptions.findIndex((option) => option.key === selected.key) > -1 ? counter + 1 : counter
  ), 0) === dropdownOptions.length;

  const handleChange = (event: any, selected: (DropdownOptions)[]) => {
    let dropdownSelected = selected.filter((option) => !option.custom && option.key !== 'SELECT_ALL');
    const customSelected = selected.filter((option) => option.custom);

    if (selected.findIndex((option) => option.key === 'SELECT_ALL') > -1) {
      dropdownSelected = !allSelected ? dropdownOptions : [];
    }

    setDropdownOptions([...dropdownSelected, ...customSelected]);
    const dropdownKeys = dropdownSelected.map((option) => option.key).join('-');
    const customKeys = customSelected.map((option) => option.label).join(',');
    formik.setFieldValue(inputName, allowCustomOptions ? `${dropdownKeys}+${customKeys}` : `${dropdownKeys}`);
  };

  const maxValuesSelected = maxSelection ? selectedValue.length >= maxSelection : false;

  useEffect(() => {
    if (initValue) {
      const options = initValue.split('+');

      const dropdown: DropdownOptions[] = [];
      options[0].split('-').forEach((key) => {
        const opt = dropdownOptions.find((option) => option.key === key);
        if (opt !== undefined) {
          dropdown.push(opt);
        }
      });
      const rand = Math.random().toString(10).slice(2);
      const custom = allowCustomOptions && options[1] && options[1].length > 0 ? (
        options[1].split(',').map((label):DropdownOptions => ({ custom: true, key: `CUSTOM_${rand}`, label }))) : (
        []);

      setDropdownOptions([...dropdown, ...custom]);
      formik.setFieldValue(inputName, initValue);
    }
  }, [initValue]);

  const textAdornment = helpLink || helpText ? (
    <InputAdornment
      position="end"
      style={{
        float: 'right',
        top: '55%',
        position: 'absolute',
        right: '65px',
      }}
    >
      { helpText && (
        <Tooltip title={helpText}>
          <InfoOutlinedIcon fontSize="small" style={{ cursor: 'default', marginBottom: '5px', marginRight: helpLink ? '10px' : '0' }} />
        </Tooltip>
      )}
      { helpLink && (
        <Link href={helpLink} target="_blank">
          <HelpOutlineOutlinedIcon fontSize="small" />
        </Link>
      )}
    </InputAdornment>
  ) : null;

  return (
    <FormControl
      variant="outlined"
      style={{ width: '100%', margin: 1 }}
    >
      <Autocomplete
        id={inputName}
        value={selectedValue}
        disableCloseOnSelect
        getOptionDisabled={(options) => maxValuesSelected}
        multiple
        onChange={handleChange}
        options={dropdownOptions}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={formik.touched[inputName] && Boolean(formik.errors[inputName])}
            label={title}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  { textAdornment }
                  { params.InputProps.endAdornment }
                </>
              ),
            }}
          />
        )}
        style={{ marginTop: '16px' }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.label);
          if (allowCustomOptions && inputValue !== '' && !isExisting) {
            const rand = Math.random().toString(10).slice(2);
            filtered.push({
              key: `CUSTOM_${rand}`,
              label: inputValue,
              custom: true,
            });
          }

          return selectAll ? [{ custom: false, key: 'SELECT_ALL', label: 'Select All' }, ...filtered] : filtered;
        }}
        renderOption={(props, option, { inputValue, selected, ...rest }) => {
          const selectAllProps = option.key === 'SELECT_ALL' ? { checked: allSelected } : {};
          const matches = match(option.label, inputValue, { insideWords: true });
          const parts = parse(option.label, matches);

          const rendererText = parts.map((part:any, index:any) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ));

          return (
            !option.custom ? (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  {...selectAllProps}
                />
                {rendererText}
              </li>
            ) : (
              <li {...props}>
                Create custom entry:&nbsp;
                {rendererText}
              </li>
            ));
        }}
      />
      <FormHelperText>{formik.touched[inputName] && formik.errors[inputName]}</FormHelperText>
      <FormHelperText style={{ color: UoMBlue }}>{maxValuesSelected && `Maximum number of ${title} selections reached.`}</FormHelperText>
    </FormControl>
  );
}

MultiSelectField.defaultProps = defaultProps;
export default MultiSelectField;
