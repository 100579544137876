import { Alert, CircularProgress, Link, Snackbar, Stack, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resendEmailSchool } from 'src/utils/schoolsApi';
import { resendEmailStudent } from 'src/utils/studentApi';

interface IWarningMessageProps {
  content: string;
  resendEmail?: boolean;
  user?: string;
}

function WarningMessage({ content, resendEmail, user }: IWarningMessageProps) {
  const navigate = useNavigate();
  const [pedding, setPedding] = useState(false);
  const handleClick = useCallback(async () => {
    let resendResponse:any;
    setPedding(true);
    if (user === 'student') {
      resendResponse = await resendEmailStudent(localStorage.getItem('email') || '');
    }

    if (user === 'school') {
      resendResponse = await resendEmailSchool(localStorage.getItem('email') || '');
    }
    if (resendResponse.status === StatusCodes.OK) {
      setPedding(false);
      navigate('/check-email', { replace: true });
    }
  }, [user, navigate]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {resendEmail
        ? (
          <Alert
            severity="warning"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {content}
            {' '}
            You can
            {' '}
            <Link
              component="button"
              onClick={handleClick}
            >
              Click here to resend verification email
            </Link>
            {' '}
            {pedding && <CircularProgress size={14} />}
          </Alert>
        )
        : <Alert severity="warning">{content}</Alert>}
    </Stack>
  );
}

export default WarningMessage;
