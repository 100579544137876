import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React from 'react';

type Props = {
  title: string;
  required?: boolean;
  onChange: Function;
};

const defaultProps = {
  required: false,
};

function PDFUpload({ title, required, onChange }: Props) {
  const convertFileToBase64 = (file: any) => (
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
  );

  const handleChange = async (event: any) => {
    const fileObject = event.target.files[0];
    const fileName = fileObject.name;
    const file = await convertFileToBase64(fileObject);
    onChange(fileName, file);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ width: '100%', margin: 1 }}
    >
      <h3>{title}</h3>
      <TextField type="file" inputProps={{ accept: 'application/pdf' }} required={required} onChange={handleChange} />
    </FormControl>
  );
}

PDFUpload.defaultProps = defaultProps;
export default PDFUpload;
