import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { UoMBlue } from 'src/app/color';

function About() {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: 'url(images/about_banner.png)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '400px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundColor: UoMBlue,
            flexGrow: 1,
            display: 'flex',
            position: 'absolute',
            bottom: '0',
            margin: '0 15%',
            width: '70%',
          }}
        >
          <Grid container sx={{ minWidth: '100%' }}>
            <Grid item xs={12}>
              <h3 style={{ color: 'white', margin: '0 14px 10px' }}>
                About UniConnectED
              </h3>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid container xs={12} sm={10} md={6} sx={{ margin: '24px auto' }}>
        <Grid item xs={5}>
          <Grid item xs={12}>
            <h1 style={{ color: 'black', width: '100%', margin: '0 auto', lineHeight: '1.5em', hyphens: 'manual', wordWrap: 'break-word' }}>
              The goal of Uni&shy;ConnectED is to flip the employment model through connecting industry partners and university students through a dedicated network
            </h1>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid item xs={12}>
            <p style={{ width: '80%', margin: '14px auto', lineHeight: '1.5em' }}>
              We aim to create greater opportunities for students to experience workplaces that extend and expand their knowledge and are related to their professional degrees.
            </p>
            <p style={{ width: '80%', margin: '14px auto', lineHeight: '1.5em' }}>
              We have bigger dreams as well.
            </p>
            <p style={{ width: '80%', margin: '14px auto', lineHeight: '1.5em' }}>
              UniConnectED will be a community hub where academics, industry partners and students can network,
              connecting in ways that improve research impact, learning relevance and societal advancement.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default About;
