import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import PDFUpload from 'src/components/Register/component/PDFUpload';
import { useNavigate } from 'react-router-dom';
import { deleteResume, getProfile, getResume, postResume, putResume, setPublish } from 'src/utils/studentApi';
import { StatusCodes } from 'http-status-codes';
import Notification from './Notification';
import { blackLight, blackDark, UoMBlue } from '../../../app/color';
import {
  availableMatch,
  courseMatch,
  courseSpecializationMatch,
  courseProgressMatch,
  firstAidCertificateMatch,
  learningAreaMatch,
  locationsMatch,
  skillMatch,
} from '../../../common/FieldsMatches/studentProfileFieldsMatch';
import { fieldFullNameConveter } from '../../../common/functions/fieldFullNameConveter';

interface TextFieldInfo {
  id: string;
  label: string;
  value: string;
}
type JsonObject = {
  [key: string]: string;
};

type ResumeJson = {
  resumeName: string;
  resumeFile: string;
};

const fieldName = ['firstName', 'lastName', 'preferredName',
  'email', 'pronouns', 'course', 'courseSpecialization',
  'courseProgression', 'currentLocation', 'workWithChildren',
  'firstAidCertificate', 'otherSkillExperience', 'locationOption',
  'learningAreas', 'available', 'image'];
const fieldMatch: JsonObject = {
  firstName: 'First Name:',
  lastName: 'Last Name:',
  preferredName: 'Preferred Name:',
  email: 'Email Address:',
  pronouns: 'Pronouns:',
  course: 'Course:',
  courseSpecialization: 'Course Specialization',
  courseProgression: 'Course Progression:',
  currentLocation: 'Current Location:',
  workWithChildren: 'Working With Children Check:',
  firstAidCertificate: 'First Aid Certificate:',
  otherSkillExperience: 'Skills:',
  locationOption: 'Open To Work Locations:',
  learningAreas: 'Learning Areas:',
  available: 'Available For:',
  image: 'Image',
  modifiedTime: 'Last Modified:',
};

let image:string;

async function fetchUserData() {
  const response = getProfile(localStorage.getItem('userId') || '');
  const { data } = await response;
  image = data.image;
  const fields = fieldName.map((name) => ({ id: name, label: fieldMatch[name], value: data[name] }));
  fields[5].value = courseMatch[fields[5].value];
  fields[6].value = (courseSpecializationMatch[data.course] && courseSpecializationMatch[data.course][data.courseSpecialization]) || 'N/A';
  fields[7].value = courseProgressMatch[fields[7].value];
  fields[8].value = locationsMatch[fields[8].value];
  const firstAidCertificate = fieldFullNameConveter(fields[10].value, firstAidCertificateMatch);
  fields[10].value = firstAidCertificate;
  const skills = fieldFullNameConveter(fields[11].value, skillMatch);
  fields[11].value = skills;
  const locations = fieldFullNameConveter(fields[12].value, locationsMatch);
  fields[12].value = locations;
  const areas = fieldFullNameConveter(fields[13].value, learningAreaMatch);
  fields[13].value = areas;
  const availables = fieldFullNameConveter(fields[14].value, availableMatch);
  fields[14].value = availables;
  if (data.workWithChildren === true) {
    fields[9].value = 'Yes';
  } else { fields[9].value = 'No'; }
  return [fields, data.isPublic, data.modifiedTime, data.resumeName];
}

async function downloadResume(onErrorCallback: any) {
  try {
    const response = getResume(localStorage.getItem('userId') || '');
    const { data } = await response;
    const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'Resume.pdf');
    document.body.appendChild(fileLink);
    fileLink.click();
  } catch (error: any) {
    if (error.response.status === StatusCodes.BAD_REQUEST) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else {
      onErrorCallback({
        shown: true,
        message: 'An unexpected error occurred',
      });
    }
  }
}

async function removeResume() {
  const response = deleteResume(localStorage.getItem('userId') || '');
  const { data } = await response;

  if (data === 'File deleted successfully') {
    window.location.reload();
  }
}

async function uploadResume(jsonObj: ResumeJson | undefined, onErrorCallback: any) {
  try {
    const response = postResume(localStorage.getItem('userId') || '', jsonObj);
    const { data } = await response;
    if (data === 'File uploaded successfully') {
      window.location.reload();
    }
  } catch (error: any) {
    if (error.response.status === StatusCodes.BAD_REQUEST) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else {
      onErrorCallback({
        shown: true,
        message: 'An unexpected error occurred',
      });
    }
  }
}

async function editResume(jsonObj: ResumeJson | undefined, onErrorCallback: any) {
  try {
    const response = putResume(localStorage.getItem('userId') || '', jsonObj);
    const { data } = await response;
    if (data === 'File uploaded successfully') {
      window.location.reload();
    }
  } catch (error: any) {
    if (error.response.status === StatusCodes.BAD_REQUEST) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      onErrorCallback({
        shown: true,
        message: error.response.data,
      });
    } else {
      onErrorCallback({
        shown: true,
        message: 'An unexpected error occurred',
      });
    }
  }
}

function ProfilePage() {
  const navigate = useNavigate();
  const [isPublished, setIsPublished] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [fieldInfo, setInfo] = useState<TextFieldInfo[]>([]);
  const [open, setOpen] = React.useState(false);
  const [resumeName, setResumeName] = React.useState(null);
  const [deleteResumeOpen, setDeleteResumeOpen] = React.useState(false);
  const [uploadResumeOpen, setUploadResumeOpen] = React.useState(false);
  const [editResumeOpen, setEditResumeOpen] = React.useState(false);
  const [resumeFormObject, setResumeFormObject] = React.useState<ResumeJson>();

  useEffect(() => {
    fetchUserData()
      .then((list) => {
        setInfo(list[0]);
        setIsPublished(list[1]);
        setResumeName(list[3]);
        setDataLoading(false);
        const givenTimestamp = new Date(list[2]);
        const currentTimestamp = new Date();
        const sixMonthsFromNow = new Date(givenTimestamp);
        sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
        if (currentTimestamp > sixMonthsFromNow) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      })
      .catch((error) => {
        if (error.response.status >= StatusCodes.UNAUTHORIZED) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [navigate]);
  return (
    <Grid
      container
      sx={{
        padding: '16px',
        minHeight: '100vh',
        height: 'auto',
        minWidth: '365px',
        justifyContent: 'center',
      }}
    >
      <Snackbar
        open={warning.shown}
        autoHideDuration={5000}
        onClose={() => setWarning({ shown: false, message: '' })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setWarning({ shown: false, message: '' })}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {warning.message}
        </Alert>
      </Snackbar>
      <Grid
        item
        sm={12}
        md={4}
        lg={4}
        sx={{ height: 'auto', margin: '0 !important', padding: '0 !important', width: '100% !important' }}
      >
        <Box
          sx={{
            padding: '16px',
            width: 'auto',
            height: '98.5%',
          }}
        >
          <Card
            sx={{
              padding: '16px',
              minWidth: '343px',
              height: '100%',
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: 26, color: 'primary.main' }}
            >
              Preview
            </Typography>
            { dataLoading ? <CircularProgress sx={{ marginLeft: '45%' }} /> : (
              <>
                <Card sx={{ mt: 2,
                  minWidth: '300px',
                  maxWidth: '300px',
                  margin: '24px auto' }}
                >
                  <br />
                  <CardMedia
                    component="img"
                    src={image}
                    style={{
                      objectFit: 'contain',
                      height: '300px',
                      backgroundColor: 'white',
                    }}
                  />
                  <CardContent sx={{ backgroundColor: 'white', height: '150px', overflowY: 'auto', overflowX: 'hidden' }}>
                    <Typography variant="body1" color={UoMBlue}>
                      {`${fieldInfo.at(0)?.value.toString()} ${fieldInfo.at(1)?.value.toString()}` }
                    </Typography>
                    <Typography variant="h6" color={blackDark}>
                      {fieldInfo.at(13)?.value}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={blackLight}
                      sx={{
                        minWidth: '266px',
                        maxWidth: '266px' }}
                    >
                      {fieldInfo.at(14)?.value}
                    </Typography>
                  </CardContent>
                </Card>
                <Box sx={{ display: 'flex', justifyContent: 'center', height: 'fit-content ', marginTop: '50px', marginBottom: '20px' }}>
                  <FormControlLabel
                    sx={{
                      display: 'block',
                      mr: '16px',
                    }}
                    control={(
                      <Switch
                        checked={isPublished}
                        onChange={() => {
                          setIsPublished(!isPublished);
                          setPublish(localStorage.getItem('userId') || '');
                        }}
                        name="isPublished"
                        color="primary"
                      />
                    )}
                    label="Published"
                  />
                </Box>
              </>
            )}
          </Card>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        md={8}
        lg={8}
        sx={{ height: 'auto', margin: '0 !important', padding: '0 !important', width: '100% !important' }}
      >
        <Box
          sx={{
            padding: '16px',
            height: '98.5%',
            width: 'auto',
          }}
        >
          <Card
            sx={{
              padding: '16px',
              minWidth: '343px',
              height: '100%',
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: 26, color: 'primary.main' }}
            >
              Profile Detail
            </Typography>
            { dataLoading ? <CircularProgress sx={{ marginLeft: '45%' }} /> : (
              <>
                <Grid
                  container
                >
                  <Grid
                    item
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{ minWidth: '311px' }}
                  >
                    <List sx={{ width: '100%' }}>
                      {fieldInfo.slice(0, 8).map((field: TextFieldInfo) => (
                        <ListItem key={field.id} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0' }}>
                          <ListItemText
                            primary={field.label}
                            secondary={field.value}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{ minWidth: '311px' }}
                  >
                    <List sx={{ width: '100%' }}>
                      {fieldInfo.slice(8, 15).map((field: TextFieldInfo) => (
                        <ListItem key={field.id} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0' }}>
                          <ListItemText
                            primary={field.label}
                            secondary={field.value}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: 14,
                              },
                            }}
                          />
                        </ListItem>
                      ))}
                      <ListItem key={resumeName} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0' }}>
                        <ListItemText
                          primary="Resume"
                          secondary={(
                            <Stack direction="row">
                              { resumeName === null ? (
                                <IconButton
                                  aria-label="upload"
                                  color="primary"
                                  style={{ paddingLeft: '0' }}
                                  onClick={() => setUploadResumeOpen(true)}
                                >
                                  <FileUploadIcon />
                                </IconButton>
                              ) : (
                                <>
                                  <IconButton
                                    aria-label="download"
                                    color="primary"
                                    style={{ paddingLeft: '0' }}
                                    onClick={() => downloadResume(setWarning)}
                                  >
                                    <FileDownloadIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => setEditResumeOpen(true)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => setDeleteResumeOpen(true)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </>
                              )}
                            </Stack>
                          )}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: 14,
                            },
                          }}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5rem' }}>
                  <Button onClick={() => { navigate('/profile-edit'); }} variant="contained" color="primary" sx={{ mr: '16px', marginBottom: '20px' }}>
                    Edit
                  </Button>
                </Box>
              </>
            )}
          </Card>
        </Box>
      </Grid>
      {open && <Notification open={open} handleClose={() => setOpen(false)} />}

      <Dialog
        open={uploadResumeOpen}
        onClose={() => setUploadResumeOpen(false)}
      >
        <DialogContent>
          <PDFUpload
            onChange={(name: string, file: any) => {
              setResumeFormObject({ resumeName: name, resumeFile: file });
            }}
            title="Upload Resume (PDF)"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadResumeOpen(false)}>Cancel</Button>
          <Button
            disabled={resumeFormObject === undefined}
            onClick={() => {
              uploadResume(resumeFormObject, setWarning);
              setUploadResumeOpen(false);
              setResumeFormObject(undefined);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editResumeOpen}
        onClose={() => setEditResumeOpen(false)}
      >
        <DialogContent>
          <PDFUpload
            onChange={(name: string, file: any) => {
              setResumeFormObject({ resumeName: name, resumeFile: file });
            }}
            title="Edit Resume (PDF)"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditResumeOpen(false)}>Cancel</Button>
          <Button
            disabled={resumeFormObject === undefined}
            onClick={() => {
              editResume(resumeFormObject, setWarning);
              setEditResumeOpen(false);
              setResumeFormObject(undefined);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteResumeOpen}
        onClose={() => setDeleteResumeOpen(false)}
        aria-labelledby="block-dialog-title"
        aria-describedby="block-dialog-description"
      >
        <DialogTitle id="block-dialog-title">
          Are you sure you wish to proceed?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="block-dialog-description">
            Are you sure you want to delete your resume?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteResumeOpen(false)}>Cancel</Button>
          <Button onClick={removeResume} autoFocus>Proceed</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default ProfilePage;
