import { createTheme } from '@mui/material/styles';
import { UoMBlue, emeraldDark, orangeDark, greenDark, yellowDark, blueDark, tealDark } from './color';
import '@fontsource/noto-serif';
import '@fontsource/source-sans-pro';

const theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro',
    h1: {
      fontFamily: 'Noto Serif',
    },
  },
  palette: {
    primary: {
      main: UoMBlue,
    },
    secondary: {
      main: tealDark,
    },
    error: {
      main: orangeDark,
    },
    success: {
      main: greenDark,
    },
    warning: {
      main: yellowDark,
    },
    info: {
      main: blueDark,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: orangeDark,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#fff',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 512, // phone
      md: 1024, // tablet
      lg: 1440, // desktop
      xl: 1920,
    },
  },
});

export default theme;
