import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { learningAreaMatch, availableMatch, courseMatch, courseProgressMatch, locationsMatch, skillMatch, firstAidCertificateMatch } from 'src/common/FieldsMatches/studentProfileFieldsMatch';
import { fieldFullNameConveter, filterFieldFullNameConveter } from 'src/common/functions/fieldFullNameConveter';
import ProfileCardWithHeart from 'src/components/ProfileCard/ProfileCardWithHeart';
import { getLikeProfile } from 'src/utils/schoolsApi';
import { Student } from '../TeacherCandidatesPage/TeacherCandidatesPage';

function Interest() {
  const [studentsInfo, setStudentsInfo] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const getPublicStudentsInfo = async () => {
    const studentsInfoResponse = await getLikeProfile(localStorage.getItem('userId') || '');
    setDataLoading(false);
    if (studentsInfoResponse.status === StatusCodes.OK) {
      const studentData = studentsInfoResponse.data.map((item: any) => ({
        ...item,
        learningAreas: fieldFullNameConveter(item.learningAreas, learningAreaMatch), // not group other option because for search feature
        available: filterFieldFullNameConveter(item.available, availableMatch), // group other option (multi selections)
        course: fieldFullNameConveter(item.course, courseMatch), // not group other option (single selection)
        courseProgression: fieldFullNameConveter(item.courseProgression, courseProgressMatch), // not group other option (single selection)
        locationOption: filterFieldFullNameConveter(item.locationOption, locationsMatch), // group other option (multi selections)
        otherSkillExperience: filterFieldFullNameConveter(item.otherSkillExperience, skillMatch), // group other option (multi selections)
        currentLocation: filterFieldFullNameConveter(item.currentLocation, locationsMatch), // group other option (multi selections)
        firstAidCertificate: fieldFullNameConveter(item.firstAidCertificate, firstAidCertificateMatch),
        workWithChildren: item.workWithChildren ? 'Yes' : 'No',
      }));
      setStudentsInfo(studentData);
    }
  };

  useEffect(() => {
    getPublicStudentsInfo();
  }, []);

  const favouritesContent = studentsInfo.length ? studentsInfo.map((student: Student) => (
    <Grid
      key={student.id}
      item
      xs={12}
      sm={6}
      md={6}
      lg={3}
    >
      <Box>
        <ProfileCardWithHeart
          key={student.id}
          id={student.id}
          image={student.image}
          isLike
          page="interest"
          info={student}
        />
      </Box>
    </Grid>
  )) : (
    <Box
      sx={{
        p: 2,
        mt: 2,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="button"
        display="block"
        sx={{
          fontSize: 16,
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        No data...
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      { dataLoading ? <CircularProgress sx={{ marginLeft: '49%', marginTop: '40px' }} /> : (favouritesContent) }
    </Grid>
  );
}

export default Interest;
