import React, { useEffect, useState } from 'react';
import { Grid, Link } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { UoMBlue } from 'src/app/color';

function Team() {
  return (
    <>
      <Grid xs={12}>
        <h1 style={{ color: 'black', fontSize: '60px', margin: '40px auto', width: 'fit-content' }}>
          Meet The Team
        </h1>
      </Grid>
      <Grid container xs={12} sm={12} md={9} sx={{ margin: '24px auto' }}>
        <Grid item xs={12} sm={6} md={4}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundImage: 'url(images/team_amanda.png)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '10em',
              width: '10em',
              position: 'relative',
              margin: '0 auto',
              borderRadius: '5em',
              border: `solid 2px ${UoMBlue}`,
            }}
          />
          <Grid item xs={12}>
            <p style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Project Lead</p>
          </Grid>
          <Grid item xs={12}>
            <h2 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Dr. Amanda Samson</h2>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: '75%', margin: '0 auto', lineHeight: '2.2em', textAlign: 'center' }}>
              Amanda is the founder of UniConnectED. She is a Lecturer at the Faculty of Education, University of Melbourne.
              Her expertise is in leadership education, engagement and partnerships.
              Prior to joining the Faculty, she worked in education consulting, professional development and executive search.
              Her research focuses on engagement between universities, students and industry partners, mentoring and leadership.
            </p>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: 'fit-content', margin: '1em auto' }}>
              <Link href="https://findanexpert.unimelb.edu.au/profile/62954-amanda-samson" target="_blank">
                <LanguageIcon />
              </Link>
              <Link href="https://www.linkedin.com/in/dr-amanda-samson/" target="_blank">
                <LinkedInIcon />
              </Link>
            </p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundImage: 'url(images/team_eduardo.png)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '10em',
              width: '10em',
              position: 'relative',
              margin: '0 auto',
              borderRadius: '5em',
              border: `solid 2px ${UoMBlue}`,
            }}
          />
          <Grid item xs={12}>
            <p style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Associate</p>
          </Grid>
          <Grid item xs={12}>
            <h2 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Dr. Eduardo Oliveira</h2>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: '75%', margin: '0 auto', lineHeight: '2.2em', textAlign: 'center' }}>
              Eduardo is a Teaching Fellow at the School of Computing and Information Systems, University of Melbourne.
              His expertise is in the use of artificial intelligence to model and assist tertiary students in digital learning environments.
              His research focuses on the use of machine learning, natural language processing,
              smart learning environments and intelligent tutoring systems combined with models of self-regulated learning to support students' journeys.
            </p>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: 'fit-content', margin: '1em auto' }}>
              <Link href="https://findanexpert.unimelb.edu.au/profile/653031-eduardo-araujo-oliveira" target="_blank">
                <LanguageIcon />
              </Link>
              <Link href="https://www.linkedin.com/in/oliveirae/" target="_blank">
                <LinkedInIcon />
              </Link>
            </p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundImage: 'url(images/team_suhrid.png)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '10em',
              width: '10em',
              position: 'relative',
              margin: '0 auto',
              borderRadius: '5em',
              border: `solid 2px ${UoMBlue}`,
            }}
          />
          <Grid item xs={12}>
            <p style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Tech Lead</p>
          </Grid>
          <Grid item xs={12}>
            <h2 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Suhrid Gupta</h2>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: '75%', margin: '0 auto', lineHeight: '2.2em', textAlign: 'center' }}>
              Suhrid is a technical lead at UniConnectED, and is responsible for its development and maintenance. His background is in full-stack development and cloud computing.
              He holds a Master of Computer Science degree at The University of Melbourne,
              with his research focusing on the use of machine learning models to improve the performance of edge computing autoscaling solutions.
            </p>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: 'fit-content', margin: '1em auto' }}>
              <Link href="https://www.linkedin.com/in/suhridgupta/" target="_blank">
                <LinkedInIcon />
              </Link>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Team;
