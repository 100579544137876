import { Paper, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Link, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import './Register.scss';
import { UoMBlue } from 'src/app/color';
import { signup } from 'src/utils/schoolsApi';
import { EMAIL_EXISTS_ERROR, INTERNAL_SERVER_ERROR, USERNAME_PASSWORD_MISMATCH_ERROR } from 'src/common/constants/ErrorMessages';
import { StatusCodes } from 'http-status-codes';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import ImageUploadV1 from './component/ImageUploadV1';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Copyright from 'src/components/Copyright/Copyright';
import ImageUploadV2 from './component/ImageUploadV2';
import WarningMessage from '../Login/components/WarningMessage/WarningMessage';
import SuccessMessage from './SuccessMessage/SuccessMessage';
import LoadingMessage from '../ForgottenPassword/Loading/LoadingMessage';
import TermOfUse from '../TermOfUse/TermOfUse';
import UniConnectEDlogo from '../Logo/UniConnectEDlogo';
import { locationsMatch, sectorMatch, yearMatch } from '../../common/FieldsMatches/studentProfileFieldsMatch';
import SingleSelectField from '../Form/SingleSelectField';
import MultiSelectField from '../Form/MultiSelectField';

function Register(this: any) {
  const navigate = useNavigate();
  const [base64Data, setbase64Data] = React.useState('');
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const [loading, setLoading] = useState({
    shown: false,
    message: '',
  });
  const validationSchema = Yup.object({
    email: Yup
      .string()
      .trim()
      .email('Enter a valid email')
      .required('Email is required')
      .matches(/^[\w-.]+@((?!student\.)[\w-]+\.)+(edu|education|gov|vic)(\.[\w-]{2,4})?$/, 'Email must be school email with edu, education, gov, or vic in it, student emails are not allowed'),
    password: Yup
      .string()
      .required('Password is required')
      .min(8, 'Password should be of minimum 8 characters length'),
    webAddress: Yup
      .string()
      .url('Invalid website format. Example: http://www.google.com')
      .required('Website address is required.'),
    schoolName: Yup
      .string()
      .required('Partner name is required.'),
    contactName: Yup
      .string()
      .required('Contact name is required.'),
    location: Yup
      .string()
      .required('Location is required.'),
    sector: Yup
      .string()
      .required('Sector is required.'),
    checkpassword: Yup
      .string()
      .oneOf([Yup.ref('password'), ''], "Passwords don't match!")
      .required('Please repeat your password.'),
    image: Yup
      .string().required('Please upload a school image/logo.'),
    yearLevel: Yup
      .string().required('Year level is required.'),
    termOfUse: Yup
      .boolean().isTrue('Please read and check the term of use.'),
  });

  const submitForm = useCallback(async (value: any) => {
    try {
      const jsonObj = {
        image: value.image,
        email: value.email,
        password: value.password,
        location: value.location,
        sector: value.sector,
        webAddress: value.webAddress,
        yearLevels: value.yearLevel,
        schoolName: value.schoolName,
        contactName: value.contactName,
      };
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Loading ... ',
      });
      const signupResponse = await signup(
        jsonObj,
      );
      if (signupResponse.status === StatusCodes.OK) {
        localStorage.setItem('email', value.email);
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Your account is created successfully.',
        });
        // navigate('/');
        setTimeout(() => {
          navigate('/check-email');
        }, 3000);
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: INTERNAL_SERVER_ERROR,
          });
        }
        if (error.response.status === StatusCodes.CONFLICT) {
          setWarning({
            shown: true,
            message: EMAIL_EXISTS_ERROR,
          });
        }
        if (error.response.status === StatusCodes.METHOD_NOT_ALLOWED) {
          setWarning({
            shown: true,
            message: 'Your account is blocked. You cannot use this email to create account.',
          });
        }
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          setWarning({
            shown: true,
            message: USERNAME_PASSWORD_MISMATCH_ERROR,
          });
        }
        if (error.response.status === StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: error.response.data,
          });
        }
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      image: '',
      email: '',
      password: '',
      webAddress: '',
      schoolName: '',
      contactName: '',
      location: '',
      sector: '',
      yearLevel: '',
      checkpassword: '',
      termOfUse: false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
  });

  const callBack = (childdata:string) => {
    setbase64Data(childdata);
    formik.setFieldValue('image', childdata.toString());
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: UoMBlue,
        minHeight: '100vh',
        paddingTop: '8px',
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={7}
        component={Paper}
        elevation={5}
        square
        sx={{
          position: 'relative',
        }}
      >
        <div className="register-form" style={{ width: '100%' }}>
          <form noValidate className="form" onSubmit={formik.handleSubmit}>
            <div className="logobox">
              <UniConnectEDlogo />
            </div>
            <h1 className="title" style={{ color: UoMBlue }}>
              Partner Registration
              <Link href="/resources" target="_self">
                <HelpOutlineOutlinedIcon fontSize="small" />
              </Link>
            </h1>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="schoolName"
              label="Partner Name"
              name="schoolName"
              autoComplete="schoolName"
              autoFocus
              value={formik.values.schoolName}
              onChange={formik.handleChange}
              error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
              helperText={formik.touched.schoolName && formik.errors.schoolName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="contactName"
              label="Contact Name"
              name="contactName"
              autoComplete="name"
              value={formik.values.contactName}
              onChange={formik.handleChange}
              error={formik.touched.contactName && Boolean(formik.errors.contactName)}
              helperText={formik.touched.contactName && formik.errors.contactName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Contact Email"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="webAddress"
              label="Website Address"
              name="webAddress"
              autoComplete="webAddress"
              value={formik.values.webAddress}
              onChange={formik.handleChange}
              error={formik.touched.webAddress && Boolean(formik.errors.webAddress)}
              helperText={formik.touched.webAddress && formik.errors.webAddress}
            />

            <SingleSelectField required formik={formik} inputName="location" title="Location" selection={locationsMatch} helpLink="https://www.vic.gov.au/regional-model-department-education" />
            <SingleSelectField required formik={formik} inputName="sector" title="Sector" selection={sectorMatch} />
            <MultiSelectField required selectAll formik={formik} inputName="yearLevel" title="Year Levels" selection={yearMatch} />

            <div style={{ width: '100%' }}>
              <h3>Partner Logo / Picture*</h3>
              {/* <ImageUploadV1 handelCallback={callBack} style={{ width: '100%' }} /> */}
              <ImageUploadV2 handelCallback={callBack} style={{ width: '100%' }} />
              <FormHelperText>{formik.touched.image && formik.errors.image}</FormHelperText>
            </div>
            <h3>Set Password*</h3>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="checkpassword"
              label="Repeat Password"
              type="password"
              id="checkpassword"
              autoComplete="current-password"
              value={formik.values.checkpassword}
              onChange={formik.handleChange}
              error={formik.touched.checkpassword && Boolean(formik.errors.checkpassword)}
              helperText={formik.touched.checkpassword && formik.errors.checkpassword}
            />
            <TermOfUse formik={formik} type="school" />
            <div style={{ textAlign: 'center' }}>
              {warning.shown ? <WarningMessage content={warning.message} /> : null}
              {success.shown ? <SuccessMessage content={success.message} /> : null}
              {loading.shown ? <LoadingMessage content={loading.message} /> : null}
              <Button
                className="button"
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                SIGN UP
              </Button>
              <br />
              <Link href="/login" variant="body2">
                Already have an account? Back to Sign In
              </Link>
            </div>
          </form>
        </div>
      </Grid>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </Grid>
  );
}

export default Register;
