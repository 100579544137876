import { Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import React from 'react';
import { blackDark, blackLight, UoMBlue } from 'src/app/color';
import GradeIcon from '@mui/icons-material/Grade';
import { like } from 'src/utils/schoolsApi';
import { StatusCodes } from 'http-status-codes';
import { useNavigate } from 'react-router-dom';
import { Student } from 'src/pages/TeacherCandidatesPage/TeacherCandidatesPage';
import ProfilePopupBox from './ProfilePopupBox';

interface Props {
  image: string,
  info: Student,
  id: number,
  isLike: boolean,
  page: string,
}

function ProfileCardWithHeart({ page, isLike, image, info, id }: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [liked, setLiked] = React.useState(isLike);
  const handleLike = async () => {
    const response = await like(id.toString(), localStorage.getItem('userId') || '');
    if (response.status === StatusCodes.OK) {
      setLiked(!liked);
      if (page === 'interest') {
        window.location.reload();
      }
    }
  };
  return (
    <div>
      <ProfilePopupBox open={open} handleClose={handleClose} id={id} info={info} />
      <Card sx={{ cursor: 'pointer' }}>
        <IconButton
          title={liked ? 'Remove from interest list' : 'Add to interest list.'}
          sx={{ color: liked ? 'gold' : 'grey', position: 'relative', height: '40px', width: '40px' }}
          onClick={handleLike}
        >
          <GradeIcon fontSize="inherit" />
        </IconButton>
        <Card onClick={handleOpen}>
          <CardMedia
            component="img"
            src={image}
            style={{
              objectFit: 'contain',
              backgroundColor: 'white',
              height: '200px',
            }}
          />
          <CardContent sx={{ backgroundColor: 'white', height: '128px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Typography variant="body1" color={UoMBlue}>
              {`${info.firstName} ${info.lastName}`}
            </Typography>
            <Typography variant="h6" color={blackDark}>
              {info.learningAreas}
            </Typography>
            <Typography variant="body2" color={blackLight}>
              {info.available}
            </Typography>
          </CardContent>
        </Card>
      </Card>
    </div>
  );
}
export default ProfileCardWithHeart;
