import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import '../../Register/Register.scss';
import { UoMBlue } from 'src/app/color';
import { useNavigate } from 'react-router-dom';
import UniConnectEDlogo from '../../Logo/UniConnectEDlogo';

function FailVerifyEmail() {
  const navigate = useNavigate();
  localStorage.clear();
  return (
    <div className="background" style={{ backgroundColor: UoMBlue, height: '100vh' }}>
      <div className="register-form" style={{ height: '100vh' }}>
        <div className="form" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="logobox">
            <UniConnectEDlogo />
          </div>
          <h1 className="title" style={{ color: UoMBlue }}>Link is invalid</h1>
          <br />
          <p>
            Please double check the link and copy it in the browser.
          </p>
          <br />
          <p>
            After 72 hours, the link will be expired.
            <br />
            if the link is expired, please click the button to provide email again.
            {' '}
            <br />
            <br />
            { window.location.pathname.split('/')[1] !== 'admin' && (
            <Button
              className="button"
              type="submit"
              variant="contained"
              onClick={() => {
                navigate('/forgotten-password');
              }}
            >
              Forgotten Password
            </Button>
            ) }
            { window.location.pathname.split('/')[1] === 'admin' && (
            <Button
              className="button"
              type="submit"
              variant="contained"
              onClick={() => {
                navigate('/admin/forgotten-password');
              }}
            >
              Forgotten Password
            </Button>
            ) }
          </p>
        </div>
      </div>
    </div>
  );
}

export default FailVerifyEmail;
