import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Autocomplete, Box, Button, Card, Collapse, Grid, IconButton, IconButtonProps, InputAdornment, styled, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export interface IFilter {
  [key: string]: Array<string>,
}

export interface IFilterConfig {
  name: string;
  label: string;
  options?: Array<string>;
  type: 'search' | 'dropdown';
}

export interface IAction {
  name: string;
  label: string;
  onClick: any;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

type Props = {
  filterConfig: Array<IFilterConfig>;
  defaultFilter?: IFilter;
  onChange: any;
  customActions?: Array<IAction>;
};

const defaultProps = {
  defaultFilter: {},
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton color="inherit" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function DataFilter({ filterConfig, defaultFilter, onChange, customActions }:Props) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const emptyFilter = _.reduce(filterConfig, (result: IFilter, config: IFilterConfig) => {
    /* eslint-disable no-param-reassign */
    result[config.name] = [];
    return result;
  }, {});

  const initialFilter = _.isEmpty(defaultFilter) ? emptyFilter : (
    defaultFilter);
  const [filter, setFilter] = useState(initialFilter);

  const initialSearchValue = _(filterConfig)
    .filter((config: IFilterConfig) => config.type === 'search')
    .reduce((result: any, config: IFilterConfig) => {
      /* eslint-disable no-param-reassign */
      result[config.name] = '';
      return result;
    }, {});
  const [searchValue, setSearchValue] = React.useState(initialSearchValue);

  const handleOnFilterChange = React.useCallback((event: any, value: string[], key: string) => {
    const updatedFilter: IFilter = { ...filter, [key]: value };
    setFilter(updatedFilter);
    onChange(updatedFilter);
  }, [filter, setFilter, onChange]);

  const clearFilter = (event: any) => {
    event.stopPropagation();
    setFilter(emptyFilter);
    setSearchValue(initialSearchValue);
    onChange(emptyFilter);
  };

  // debounce does not work when in a function with setState, useMemo fixes this.
  // Ref: https://stackoverflow.com/a/64856090
  const debounceHandleOnFilterChange = useMemo(() => _.debounce(handleOnFilterChange, 500), [handleOnFilterChange]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      <Card
        sx={{ p: 2 }}
      >
        <Typography
          variant="h6"
          sx={{ color: 'primary.main', margin: '0 8px', cursor: 'pointer' }}
          onClick={handleExpandClick}
        >
          Filter
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Box sx={{ float: 'right' }}>
            { _.map(customActions, (action: IAction) => (
              <Button
                id={action.name}
                onClick={(event) => {
                  event.stopPropagation();
                  const customFilter: IFilter = action.onClick(event);
                  const updatedFilter = { ...filter, ...customFilter };
                  setFilter(updatedFilter);
                  onChange(updatedFilter);
                }}
              >
                {action.label}
              </Button>
            ))}
            <Button onClick={clearFilter}>Clear</Button>
          </Box>
        </Typography>
        <Collapse in={expanded} timeout="auto">
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {filterConfig.map((field) => (
              <Grid
                key={field.name}
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Box sx={{ display: 'flex' }}>
                  { field.type === 'dropdown' ? (
                    <Autocomplete
                      limitTags={6}
                      multiple
                      disablePortal
                      options={field.options as string[]}
                      size="small"
                      sx={{ width: '100%', m: 1 }}
                      onChange={(e, v) => handleOnFilterChange(e, v, field.name)}
                      renderInput={(params) => <TextField {...params} label={field.label} />}
                      value={filter[field.name]}
                      filterSelectedOptions
                      renderOption={(props, option, { inputValue }) => {
                        const matches = match(option, inputValue, { insideWords: true });
                        const parts = parse(option, matches);
                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part:any, index:any) => (
                                <span
                                // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '100%', m: 1 }}
                      label={field.label}
                      variant="outlined"
                      value={searchValue[field.name]}
                      onChange={(e) => {
                        setSearchValue({ ...searchValue, [field.name]: e.target.value });
                        const v = e.target.value.length ? [_.trim(e.target.value)] : [];
                        debounceHandleOnFilterChange(e, v, field.name);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Box>
              </Grid>
            ))}
          </Box>
        </Collapse>
      </Card>
    </Grid>
  );
}

DataFilter.defaultProps = defaultProps;
export default DataFilter;
