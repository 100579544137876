import { Box, Grid } from '@mui/material';
import React from 'react';
import MiniDrawer from 'src/components/MiniDrawer/MiniDrawer';
import Copyright from '../Copyright/Copyright';

function AuthLayout({ component }: any) {
  return (
    <Box sx={{ display: 'flex' }}>
      <MiniDrawer />
      <Box
        component="main"
        className="main-container"
        sx={{
          flexGrow: 1,
          height: 'calc(100vh - 64px)',
          marginTop: '64px',
          overflow: 'auto',
          backgroundColor: 'rgba(153,162,170, .2)',
        }}
      >
        {component}
        <Grid container>
          <Grid item xs={12}>
            <Copyright sx={{ mt: 5, mb: 5, paddingTop: '14px' }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AuthLayout;
