import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { StatusCodes } from 'http-status-codes';
import SortIcon from '@mui/icons-material/Sort';
import { Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import {
  availableMatch,
  courseMatch,
  courseProgressMatch,
  learningAreaMatch,
  locationsMatch,
  skillMatch,
  firstAidCertificateMatch,
  courseSpecializationMatch,
  availbleList,
  courseList,
  courseProgressList,
  courseSpecializationList,
  learningAreaList,
  locationList,
  skillList,
  learningAreaMTSIList,
} from 'src/common/FieldsMatches/studentProfileFieldsMatch';
import { useNavigate } from 'react-router-dom';
import { fieldFullNameConveter, filterFieldFullNameConveter } from 'src/common/functions/fieldFullNameConveter';
import { blockIdStudent, deleteIdStudent, getStudentsInfo } from 'src/utils/adminApi';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { checkInFilter } from 'src/common/functions/checkInFilter';
import DataFilter, { IAction, IFilter, IFilterConfig } from '../DataFilter/DataFilter';
import LoadingMessage from '../ForgottenPassword/Loading/LoadingMessage';
import WarningMessage from '../Login/components/WarningMessage/WarningMessage';
import SuccessMessage from '../Register/SuccessMessage/SuccessMessage';

interface ITableData {
  id: number;
  firstName: string;
  lastName: string;
  studentNumber: string;
  email: string;
  course: string;
  courseSpecialization: string;
  courseProgression: string;
  currentLocation: string;
  workWithChildren: boolean;
  firstAidCertificate: string;
  otherSkillExperience: string;
  locationOption: string;
  learningAreas: string;
  available: string;
  verification: boolean;
  modifiedTime: string;
}

const filterTextFields: Array<IFilterConfig> = [
  { name: 'studentName', label: 'Student Name', type: 'search' },
  { name: 'email', label: 'Email', type: 'search' },
  { name: 'verification', label: 'Verified', options: ['Yes', 'No'], type: 'dropdown' },
  { name: 'course', label: 'Course', options: courseList, type: 'dropdown' },
  { name: 'courseSpecialization', label: 'Course Specialization', options: courseSpecializationList, type: 'dropdown' },
  { name: 'courseProgression', label: 'Course Progression', options: courseProgressList, type: 'dropdown' },
  { name: 'learningAreas', label: 'Learning Areas', options: [...learningAreaList, 'Other'], type: 'dropdown' },
  { name: 'locationOption', label: 'Open To Work Locations', options: [...locationList, 'Other'], type: 'dropdown' },
  { name: 'otherSkillExperience', label: 'Skills', options: [...skillList, 'Other'], type: 'dropdown' },
  { name: 'available', label: 'Available For', options: [...availbleList, 'Other'], type: 'dropdown' },
];

const customActions: Array<IAction> = [
  {
    name: 'mtsiFilter',
    label: 'MTSI',
    onClick: () => ({ course: ['Master of Teaching'], courseSpecialization: ['Secondary Internship'], learningAreas: learningAreaMTSIList }),
  },
];

const getPublicStudentsInfo = async () => {
  const studentsInfoResponse = await getStudentsInfo();
  return studentsInfoResponse.data;
};

function StudentTable() {
  const navigate = useNavigate();
  const [studentsInfo, setStudentsInfo] = useState(Array<ITableData>);
  const [filteredInfo, setFilteredInfo] = useState(Array<ITableData>);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<'id' | 'modifiedTime'>('id');

  useEffect(() => {
    getPublicStudentsInfo()
      .then((data) => {
        const sortedData = [...data].sort((a, b) => {
          if (sortColumn === 'id') {
            if (sortDirection === 'asc') {
              return a.id - b.id;
            }
            return b.id - a.id;
          } if (sortColumn === 'modifiedTime') {
            const timeA = new Date(a.modifiedTime).getTime();
            const timeB = new Date(b.modifiedTime).getTime();
            if (sortDirection === 'asc') {
              return timeA - timeB;
            }
            return timeB - timeA;
          }
          return 0;
        });
        const studentData = sortedData.map((item: any) => ({
          ...item,
          verification: item.verification ? 'Yes' : 'No',
          learningAreas: filterFieldFullNameConveter(item.learningAreas, learningAreaMatch), // not group other option because for search feature
          available: filterFieldFullNameConveter(item.available, availableMatch), // group other option (multi selections)
          course: fieldFullNameConveter(item.course, courseMatch), // not group other option (single selection)
          courseSpecialization: fieldFullNameConveter(item.courseSpecialization, courseSpecializationMatch[item.course]),
          courseProgression: fieldFullNameConveter(item.courseProgression, courseProgressMatch), // not group other option (single selection)
          locationOption: filterFieldFullNameConveter(item.locationOption, locationsMatch), // group other option (multi selections)
          otherSkillExperience: filterFieldFullNameConveter(item.otherSkillExperience, skillMatch), // group other option (multi selections)
          currentLocation: filterFieldFullNameConveter(item.currentLocation, locationsMatch), // group other option (multi selections)
          firstAidCertificate: fieldFullNameConveter(item.firstAidCertificate, firstAidCertificateMatch),
        }));
        setStudentsInfo(studentData);
        setFilteredInfo(studentData);
      })
      .catch((error) => {
        if (error.response.status >= StatusCodes.UNAUTHORIZED) {
          localStorage.clear();
          navigate('/admin/login');
        }
      });
  }, [sortDirection, sortColumn, navigate]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const [loading, setLoading] = useState({
    shown: false,
    message: '',
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [checkboxSelected, setCheckboxSelected] = React.useState<ITableData[]>([]);

  const [blockDialogOpen, setBlockDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleBlockDialogOpen = () => setBlockDialogOpen(true);
  const handleBlockDialogClose = () => setBlockDialogOpen(false);

  const handleDeleteDialogOpen = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckboxSelected(filteredInfo);
      return;
    }
    setCheckboxSelected([]);
  };

  const onCheckboxClick = (event: React.MouseEvent<unknown>, studentInfo: ITableData) => {
    const selectedIndex = checkboxSelected.findIndex((selected) => studentInfo.id === selected.id);
    let newSelected: ITableData[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkboxSelected, studentInfo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkboxSelected.slice(1));
    } else if (selectedIndex === checkboxSelected.length - 1) {
      newSelected = newSelected.concat(checkboxSelected.slice(0, -1));
    } else {
      newSelected = newSelected.concat(
        checkboxSelected.slice(0, selectedIndex),
        checkboxSelected.slice(selectedIndex + 1),
      );
    }
    setCheckboxSelected(newSelected);
  };

  const isSelected = (studentInfo: ITableData) => checkboxSelected.findIndex((selected) => studentInfo.id === selected.id) !== -1;

  const handleSortClick = (column: 'id' | 'modifiedTime') => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDeleteClick = async (idArray: number[]) => {
    handleDeleteDialogClose();
    try {
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Loading ... ',
      });

      const promises = idArray.map((id) => deleteIdStudent(id));
      const responses = await Promise.all(promises);

      if (responses.filter((response) => response.status === StatusCodes.OK).length === idArray.length) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Delete successfully',
        });
        setTimeout(() => {
          setSucces({
            shown: false,
            message: '',
          });
        }, 5000);
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Some delete operations may have failed.',
        });
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: 'Oops! Something went wrong.',
          });
        } else if (error.response.status >= StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: 'Account not existed or not verified.',
          });
        }
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  };
  const timestampToDate = (timestamp: string) => {
    const date = new Date(timestamp);

    // Extracting date and time components
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Formatting the date and time
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return [formattedDate, formattedTime];
  };
  const handleBlockClick = async (idArray: number[]) => {
    handleBlockDialogClose();
    try {
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Loading ... ',
      });

      const promises = idArray.map((id) => blockIdStudent(id));
      const responses = await Promise.all(promises);

      if (responses.filter((response) => response.status === StatusCodes.OK).length === idArray.length) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Block successfully',
        });
        setTimeout(() => {
          setSucces({
            shown: false,
            message: '',
          });
        }, 5000);
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Some block operations may have failed.',
        });
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: 'Oops! Something went wrong.',
          });
        } else if (error.response.status >= StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: 'Account not existed or not verified.',
          });
        }
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  };

  const copyEmailsToClipboard = (event: any) => {
    navigator.clipboard.writeText(`${checkboxSelected.map(((row) => row.email)).join('; ')}`);
    setSnackbarOpen(true);
  };

  const lowerCaseArray = (array: any[]) => array.map((item) => item.toLowerCase());
  const onFilterChange = (filter: IFilter) => {
    setFilteredInfo(studentsInfo.filter((item: any) => (
      (checkInFilter(filter.studentName, `${item.firstName} ${item.lastName}`) || !lowerCaseArray(filter.studentName).length)
      && (checkInFilter(filter.email, item.email) || !lowerCaseArray(filter.email).length)
      && (checkInFilter(filter.verification, item.verification) || !lowerCaseArray(filter.verification).length)
      && (checkInFilter(filter.learningAreas, item.learningAreas) || !lowerCaseArray(filter.learningAreas).length)
      && (checkInFilter(filter.otherSkillExperience, item.otherSkillExperience) || !lowerCaseArray(filter.otherSkillExperience).length)
      && (checkInFilter(filter.available, item.available) || !lowerCaseArray(filter.available).length)
      && (lowerCaseArray(filter.course).includes(item.course.toLowerCase()) || !lowerCaseArray(filter.course).length)
      && (lowerCaseArray(filter.courseSpecialization).includes(item.courseSpecialization.toLowerCase()) || !lowerCaseArray(filter.courseSpecialization).length)
      && (lowerCaseArray(filter.courseProgression).includes(item.courseProgression.toLowerCase()) || !lowerCaseArray(filter.courseProgression).length)
      && (checkInFilter(filter.locationOption, item.locationOption) || !lowerCaseArray(filter.locationOption).length)
    )));
    setCheckboxSelected([]);
  };

  return (
    <Grid sx={{ width: '100%' }}>
      {warning.shown ? <WarningMessage content={warning.message} /> : null}
      {success.shown ? <SuccessMessage content={success.message} /> : null}
      {loading.shown ? <LoadingMessage content={loading.message} /> : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Copied to clipboard
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <DataFilter
          filterConfig={filterTextFields}
          onChange={onFilterChange}
          customActions={customActions}
        />
      </Box>
      <Grid xs={12}>
        <Typography
          variant="h6"
          sx={{ color: 'primary.main', margin: '14px 8px' }}
        >
          Actions
        </Typography>
        <Button
          variant="outlined"
          disabled={checkboxSelected.length === 0}
          onClick={copyEmailsToClipboard}
          style={{ marginRight: '5px' }}
        >
          Copy Emails
        </Button>
        <Button
          variant="outlined"
          disabled={checkboxSelected.length === 0}
          onClick={handleDeleteDialogOpen}
          style={{ marginRight: '5px' }}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          disabled={checkboxSelected.length === 0}
          onClick={handleBlockDialogOpen}
          style={{ marginRight: '5px' }}
        >
          Block
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={checkboxSelected.length > 0 && checkboxSelected.length < filteredInfo.length}
                  checked={filteredInfo.length > 0 && checkboxSelected.length === filteredInfo.length}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all students',
                  }}
                />
              </TableCell>
              <TableCell onClick={() => handleSortClick('id')}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  ID
                  {sortColumn === 'id' && (sortDirection === 'asc' ? (
                    <ArrowUpwardIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  ))}
                  {sortColumn !== 'id' && <SortIcon />}
                </div>
              </TableCell>

              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Course Specialization</TableCell>
              <TableCell>Course Progression</TableCell>
              <TableCell>Current Location</TableCell>
              <TableCell>Working With Children Check</TableCell>
              <TableCell>Certification</TableCell>
              <TableCell>Skills</TableCell>
              <TableCell>Open To Work Locations</TableCell>
              <TableCell>Learning Area</TableCell>
              <TableCell>Available For</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell onClick={() => handleSortClick('modifiedTime')}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  Last Modified
                  <br />
                  {sortColumn === 'modifiedTime' && (sortDirection === 'asc' ? (
                    <ArrowUpwardIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  ))}
                  {sortColumn !== 'modifiedTime' && <SortIcon />}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${row.id}`;
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onClick={(event) => onCheckboxClick(event, row)}
                    />
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.course}</TableCell>
                  <TableCell>{row.courseSpecialization}</TableCell>
                  <TableCell>{row.courseProgression}</TableCell>
                  <TableCell>{row.currentLocation}</TableCell>
                  <TableCell>{row.workWithChildren ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{row.firstAidCertificate}</TableCell>
                  <TableCell>{row.otherSkillExperience}</TableCell>
                  <TableCell>{row.locationOption}</TableCell>
                  <TableCell>{row.learningAreas}</TableCell>
                  <TableCell>{row.available}</TableCell>
                  <TableCell>{row.verification}</TableCell>
                  <TableCell>{`${timestampToDate(row.modifiedTime)[0]} ${timestampToDate(row.modifiedTime)[1]}`}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 50]}
        component="div"
        count={filteredInfo.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you wish to proceed?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the selected students?
            <ul>
              {checkboxSelected.map(((selected) => <li>{`${selected.firstName} ${selected.lastName}`}</li>))}
            </ul>
            This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={() => handleDeleteClick(checkboxSelected.map((selected) => selected.id))} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={blockDialogOpen}
        onClose={handleBlockDialogClose}
        aria-labelledby="block-dialog-title"
        aria-describedby="block-dialog-description"
      >
        <DialogTitle id="block-dialog-title">
          Are you sure you wish to proceed?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="block-dialog-description">
            Are you sure you want to block the selected students?
            <ul>
              {checkboxSelected.map(((selected) => <li>{`${selected.firstName} ${selected.lastName}`}</li>))}
            </ul>
            This will delete their profiles, and prevent them from creating one again.
            This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBlockDialogClose}>Cancel</Button>
          <Button onClick={() => handleBlockClick(checkboxSelected.map((selected) => selected.id))} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default StudentTable;
