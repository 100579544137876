import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

function ForbiddenPage() {
  const navigate = useNavigate();
  const handleClick = () => {
    if (localStorage.getItem('role') === 'admin') {
      navigate('/admin/dashboard');
    } else {
      navigate('/profile');
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '90vh',
    }}
    >
      <div style={{ width: '100px' }}>
        <img src="/images/uniconnected_logo.svg" alt="logo" />
      </div>
      <div><h1 style={{ margin: '24px 0 8px 0' }}>Forbidden (403)</h1></div>
      <div style={{ marginBottom: '24px' }}>Sorry, you can not access this page.</div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Back
      </Button>

    </div>
  );
}

export default ForbiddenPage;
