import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, List, ListItem, ListItemText, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { UoMBlue } from 'src/app/color';

const style = {
  position: 'absolute' as 'absolute',
  top: { xs: '60%', sm: '53%', md: '50%', lg: '50%' },
  left: { xs: '55%', sm: '52%', md: '51%', lg: '51%' },
  height: 'fit-content',
  maxHeight: '500px',
  maxWidth: '973px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  paddingTop: { xs: 0, sm: 4 },
  overflowY: 'auto',
  justifyContent: 'center',
  overflowX: 'hidden',
};

interface Props {
  open: boolean;
  handleClose: () => void;
}

function Notification({ open, handleClose }: Props) {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style} spacing={3}>
        <Button
          onClick={handleClose}
          sx={{
            position: 'sticky',
            top: '0',
            pt: 0,
            height: '8%',
            marginTop: '10px',
            width: '100%',
            left: '10%',
            zIndex: 1,
            display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' },
          }}
        >
          <CloseIcon />
        </Button>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute' as 'absolute',
            top: 0,
            pt: 3,
            pb: 3,
            pr: 5,
            pl: 5,
            right: '0%',
            zIndex: 1,
            display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
          }}
        >
          <CloseIcon />
        </Button>
        <br />
        <div style={{ alignContent: 'center', justifyContent: 'center', marginBottom: '20px', marginLeft: '25px' }}>
          <h2 style={{ color: UoMBlue, fontWeight: 'bold' }}>Notification</h2>
          <p>Your profile has not been updated in the last six months.</p>
          <p>
            Please check your profile details, make any necessary changes and update. Please note that you
            must select the SAVE button even if nothing has changed.

          </p>
          <p style={{ fontSize: '12px', color: 'grey' }}>
            Hint: Please also press the SAVE button in the edit page, if you don't need to change anything. This notification
            will continue to appear if the button is not pressed.
          </p>
          <br />
          <Button onClick={() => { navigate('/profile-edit'); }} variant="contained" color="primary" sx={{ mr: '16px', marginBottom: '10px' }}>
            Edit
          </Button>
        </div>
      </Grid>
    </Modal>
  );
}

export default Notification;
