import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { sectorMatch, yearMatch } from 'src/common/FieldsMatches/studentProfileFieldsMatch';
import { fieldFullNameConveter } from 'src/common/functions/fieldFullNameConveter';
import ProfileCard from 'src/components/ProfileCard/ProfileCard';
import { getSchoolsInfo } from 'src/utils/schoolsApi';

interface School {
  available: string,
  email: string,
  schoolName: string,
  id: number,
  image: string,
  contactName: string,
  contactEmail: string,
  webAddress: string,
  location: string,
  sector: string,
  yearLevels: string,
  isHiring: boolean,
  allowDirectContact: boolean,
}

function SchoolsListPage() {
  const [schoolsInfo, setSchoolsInfo] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const getPublicSchoolsInfo = async () => {
    const response = await getSchoolsInfo();
    setDataLoading(false);
    if (response.status === StatusCodes.OK) {
      const studentData = response.data.map((item: School) => ({
        ...item,
        sector: fieldFullNameConveter(item.sector, sectorMatch),
        yearLevels: fieldFullNameConveter(item.yearLevels, yearMatch),
      }));
      setSchoolsInfo(studentData);
    }
  };
  useEffect(() => {
    getPublicSchoolsInfo();
  }, []);

  const schoolsList = schoolsInfo.length ? schoolsInfo.map((school: School) => (
    <Grid
      key={school.id}
      item
      xs={12}
      sm={6}
      md={4}
      lg={2}
    >
      <Box>
        <ProfileCard
          key={school.id}
          image={school.image}
          showContactButton={school.allowDirectContact}
          showHiringBadge={school.isHiring}
          info={[school.yearLevels, school.schoolName, school.webAddress, school.email]}
        />
      </Box>
    </Grid>
  )) : (
    <Box
      sx={{
        p: 2,
        mt: 2,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="button"
        display="block"
        sx={{
          fontSize: 16,
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        No data...
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      { dataLoading ? <CircularProgress sx={{ marginLeft: '49%', marginTop: '40px' }} /> : schoolsList }
    </Grid>
  );
}

export default SchoolsListPage;
