import React, { useEffect, useState } from 'react';
import { getSchoolsInfo, getStudentsInfo } from 'src/utils/adminApi';
import { StatusCodes } from 'http-status-codes';
import { Box, Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import SchoolTable from 'src/components/DashboardTable/SchoolTable';
import StudentTable from 'src/components/DashboardTable/StudentTable';

function AdminDashboard() {
  const [type, setType] = useState('Student');
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Card
          sx={{ p: 2 }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="test-select-label">Type</InputLabel>
              <Select
                id="type"
                name="type"
                fullWidth
                variant="outlined"
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value="Partner">Partner</MenuItem>
                <MenuItem value="Student">Student</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Card>
        <Card
          sx={{ p: 2, marginTop: '20px' }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            { type === 'Student' && <StudentTable />}
            { type === 'Partner' && <SchoolTable />}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AdminDashboard;
