import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, ButtonBase, Collapse, Grid, Link, List, ListItem, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { UoMBlue } from 'src/app/color';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function Resources() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <Grid container xs={12} sm={12} md={9} sx={{ margin: '24px auto' }}>
        <h1 style={{ color: 'black', fontSize: '60px', margin: '40px auto', width: 'fit-content' }}>
          Resources
        </h1>
      </Grid>
      <Grid container xs={12} sm={12} md={9} sx={{ margin: '24px auto' }}>
        <Grid item xs={12} sm={6} md={4}>
          <ButtonBase
            href="https://youtu.be/JrrWscC7cv4"
            target="__blank"
            style={{ height: '100%', display: 'inherit' }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundImage: 'url(images/logo.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '10em',
                width: '10em',
                position: 'relative',
                margin: '0 auto',
                // borderRadius: '10em',
                border: `solid 2px ${UoMBlue}`,
              }}
            />
            <Grid item xs={12}>
              <h2 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Educational Partner Instructional Video</h2>
            </Grid>
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ButtonBase
            href="https://youtu.be/iI6UtNPorOQ"
            target="__blank"
            style={{ height: '100%', display: 'inherit' }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundImage: 'url(images/logo.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '10em',
                width: '10em',
                position: 'relative',
                margin: '0 auto',
                // borderRadius: '10em',
                border: `solid 2px ${UoMBlue}`,
              }}
            />
            <Grid item xs={12}>
              <h2 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Education Student Instructional Video</h2>
            </Grid>
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ButtonBase
            href="https://youtu.be/8gydib-11nc"
            target="__blank"
            style={{ height: '100%', display: 'inherit' }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundImage: 'url(images/logo.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '10em',
                width: '10em',
                position: 'relative',
                margin: '0 auto',
                // borderRadius: '10em',
                border: `solid 2px ${UoMBlue}`,
              }}
            />
            <Grid item xs={12}>
              <h2 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Master of Teaching Secondary Internship</h2>
            </Grid>
          </ButtonBase>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={12} md={9} sx={{ margin: '24px auto' }}>
        <h1 style={{ color: 'black', fontSize: '60px', margin: '40px auto', width: 'fit-content' }}>
          Frequently Asked Questions
        </h1>
      </Grid>
      <Grid container xs={12} sm={12} md={9} sx={{ margin: '24px auto' }}>
        <Accordion sx={{ width: '100%', bgcolor: 'background.paper', border: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h3 style={{ color: 'black', marginTop: '0' }}>Does the University of Melbourne provide online resources for job seekers? </h3>
          </AccordionSummary>
          <AccordionDetails>
            The University of Melbourne has a&nbsp;
            <Link href="https://careersonline.unimelb.edu.au/students/resources/search/?order=Relevance&topicsUseAnd=true" target="__blank">careers online resources</Link>
            &nbsp;website for further information which may benefit your job search.
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ width: '100%', bgcolor: 'background.paper', border: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h3 style={{ color: 'black', marginTop: '0' }}>Are there any resources to help me create a Student profile?</h3>
          </AccordionSummary>
          <AccordionDetails>
            UniConnectED provides a&nbsp;
            <Link href="https://drive.google.com/file/d/1QZxwjvB2MhDRYJ9WquERyby3Br4PcNry/view?usp=sharing" target="__blank">guide</Link>
            &nbsp;to help set up your Student profile.
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ width: '100%', bgcolor: 'background.paper', border: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h3 style={{ color: 'black', marginTop: '0' }}>
              I am a student interested in registering for the Master of Teaching Secondary Internship (MTSI), how do I ensure employers can view my profile?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            Students interested in registering their profile for MTSI can follow&nbsp;
            <Link href="https://drive.google.com/file/d/1IHMZ7JofiSdy6XQopuaXlaU72uhpUUeQ/view?usp=sharing" target="__blank">this guide</Link>
            &nbsp; to ensure their profiles are visible to MTSI Partners.
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ width: '100%', bgcolor: 'background.paper', border: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h3 style={{ color: 'black', marginTop: '0' }}>
              Are there any resources to help me create a Partner profile?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            UniConnectED provides a&nbsp;
            <Link href="https://drive.google.com/file/d/1XBejB0k2qIutxCtWLx1sLQsV2SP_D0sV/view?usp=sharing" target="__blank">guide</Link>
            &nbsp;to help set up your Partner profile.
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ width: '100%', bgcolor: 'background.paper', border: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h3 style={{ color: 'black', marginTop: '0' }}>
              How can I set up my partner profile to search for Master of Teaching Secondary Internship (MTSI) students?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            Partners interested in setting up their profile for MTSI can follow&nbsp;
            <Link href="https://drive.google.com/file/d/1g8Gy5S_X_slCulDq83bCMYnklB7V_P8r/view?usp=sharing" target="__blank">this guide</Link>
            .
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
}

export default Resources;
