import _ from 'lodash';

export const fieldFullNameConveter = (content: string, matchTable: { [key: string]: string }) => {
  const items = content.split('+');
  const fixedValue = items[0].split('-').map((item) => matchTable[item]).join(', ');
  const others = items.length > 1 ? items[1].split(',').join(', ') : '';
  return `${fixedValue}${others && fixedValue ? ', ' : ''}${others}`;
};

// add other option
export const filterFieldFullNameConveter = (content: string, matchTable: { [key: string]: string }) => {
  const items = content.split('+');
  const fixedValue = items[0].split('-').map((item) => matchTable[item]).join(', ');
  const others = _.isEmpty(items[1]) ? '' : 'Other';
  return `${fixedValue}${others && fixedValue ? ', ' : ''}${others}`;
};
