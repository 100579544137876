import React, { useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import SortIcon from '@mui/icons-material/Sort';
import { Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { locationList, locationsMatch, sectorList, sectorMatch, yearMatch } from 'src/common/FieldsMatches/studentProfileFieldsMatch';
import { useNavigate } from 'react-router-dom';
import { blockIdSchool, deleteIdSchool, getSchoolsInfo } from 'src/utils/adminApi';
import { filterFieldFullNameConveter } from 'src/common/functions/fieldFullNameConveter';
import { checkInFilter } from 'src/common/functions/checkInFilter';
import DataFilter, { IFilter, IFilterConfig } from '../DataFilter/DataFilter';
import LoadingMessage from '../ForgottenPassword/Loading/LoadingMessage';
import WarningMessage from '../Login/components/WarningMessage/WarningMessage';
import SuccessMessage from '../Register/SuccessMessage/SuccessMessage';

interface ITableData {
  id: number;
  schoolName: string;
  contactName: string;
  email: string;
  webAddress: string;
  location: string;
  sector: string;
  isHiring: string;
  yearLevels: string;
  verification: boolean;
  count: number;
}

const filterTextFields: Array<IFilterConfig> = [
  { name: 'schoolName', label: 'School Name', type: 'search' },
  { name: 'email', label: 'Email', type: 'search' },
  { name: 'verification', label: 'Verified', options: ['Yes', 'No'], type: 'dropdown' },
  { name: 'location', label: 'Location', options: locationList, type: 'dropdown' },
  { name: 'sector', label: 'Sector', options: sectorList, type: 'dropdown' },
  { name: 'isHiring', label: 'MTSI Hiring', options: ['Yes', 'No'], type: 'dropdown' },
];

const matchYear = (year:string) => {
  const yearLevel = year.split('-');
  const newYearLevel = [];
  for (let i = 0; i < yearLevel.length; i += 1) {
    newYearLevel.push(yearMatch[yearLevel[i]]);
  }
  return newYearLevel.toString();
};

const getPublicStchoolsInfo = async () => {
  const schoolsInfoResponse = await getSchoolsInfo();
  return schoolsInfoResponse.data;
};

function SchoolTable() {
  const navigate = useNavigate();
  const [schoolsInfo, setSchoolsInfo] = useState(Array<ITableData>);
  const [filteredInfo, setFilteredInfo] = useState(Array<ITableData>);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<'id' | 'sentFromSystem'>('id');
  useEffect(() => {
    getPublicStchoolsInfo()
      .then((data) => {
        const sortedData = [...data].sort((a, b) => {
          if (sortColumn === 'id') {
            if (sortDirection === 'asc') {
              return a.id - b.id;
            }
            return b.id - a.id;
          } if (sortColumn === 'sentFromSystem') {
            if (sortDirection === 'asc') {
              return a.count - b.count;
            }
            return b.count - a.count;
          }
          // Default sorting (by id) if sortColumn is not recognized
          if (sortDirection === 'asc') {
            return a.id - b.id;
          }
          return b.id - a.id;
        });
        const schoolData = sortedData.map((item: any) => ({
          ...item,
          verification: item.verification ? 'Yes' : 'No',
          location: filterFieldFullNameConveter(item.location, locationsMatch),
          sector: filterFieldFullNameConveter(item.sector, sectorMatch),
          isHiring: item.isHiring ? 'Yes' : 'No',
        }));
        setSchoolsInfo(schoolData);
        setFilteredInfo(schoolData);
      })
      .catch((error) => {
        if (error.response.status >= StatusCodes.UNAUTHORIZED) {
          localStorage.clear();
          navigate('/admin/login');
        }
      });
  }, [sortDirection, sortColumn, navigate]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const [loading, setLoading] = useState({
    shown: false,
    message: '',
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [checkboxSelected, setCheckboxSelected] = React.useState<ITableData[]>([]);

  const [blockDialogOpen, setBlockDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleBlockDialogOpen = () => setBlockDialogOpen(true);
  const handleBlockDialogClose = () => setBlockDialogOpen(false);

  const handleDeleteDialogOpen = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckboxSelected(filteredInfo);
      return;
    }
    setCheckboxSelected([]);
  };

  const onCheckboxClick = (event: React.MouseEvent<unknown>, schoolInfo: ITableData) => {
    const selectedIndex = checkboxSelected.findIndex((selected) => schoolInfo.id === selected.id);
    let newSelected: ITableData[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkboxSelected, schoolInfo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkboxSelected.slice(1));
    } else if (selectedIndex === checkboxSelected.length - 1) {
      newSelected = newSelected.concat(checkboxSelected.slice(0, -1));
    } else {
      newSelected = newSelected.concat(
        checkboxSelected.slice(0, selectedIndex),
        checkboxSelected.slice(selectedIndex + 1),
      );
    }
    setCheckboxSelected(newSelected);
  };

  const isSelected = (schoolInfo: ITableData) => checkboxSelected.findIndex((selected) => schoolInfo.id === selected.id) !== -1;

  const handleSortClick = (column: 'id' | 'sentFromSystem') => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDeleteClick = async (idArray: number[]) => {
    handleDeleteDialogClose();
    try {
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Loading ... ',
      });

      const promises = idArray.map((id) => deleteIdSchool(id));
      const responses = await Promise.all(promises);

      if (responses.filter((response) => response.status === StatusCodes.OK).length === idArray.length) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Delete successfully',
        });
        setTimeout(() => {
          setSucces({
            shown: false,
            message: '',
          });
        }, 5000);
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Some delete operations may have failed.',
        });
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: 'Oops! Something went wrong.',
          });
        } else if (error.response.status >= StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: 'Account not existed or not verified.',
          });
        }
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  };
  const handleBlockClick = async (idArray: number[]) => {
    handleBlockDialogClose();
    try {
      setWarning({
        shown: false,
        message: '',
      });
      setLoading({
        shown: true,
        message: 'Loading ... ',
      });

      const promises = idArray.map((id) => blockIdSchool(id));
      const responses = await Promise.all(promises);

      if (responses.filter((response) => response.status === StatusCodes.OK).length === idArray.length) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setLoading({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Block successfully',
        });
        setTimeout(() => {
          setSucces({
            shown: false,
            message: '',
          });
        }, 5000);
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Some block operations may have failed.',
        });
      }
    } catch (error: any) {
      if (error.response) {
        setLoading({
          shown: false,
          message: '',
        });
        if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: 'Oops! Something went wrong.',
          });
        } else if (error.response.status >= StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: 'Account not existed or not verified.',
          });
        }
      } else {
        setLoading({
          shown: false,
          message: '',
        });
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  };

  const copyEmailsToClipboard = (event: any) => {
    navigator.clipboard.writeText(`${checkboxSelected.map(((row) => row.email)).join('; ')}`);
    setSnackbarOpen(true);
  };

  const lowerCaseArray = (array: any[]) => array.map((item) => item.toLowerCase());
  const onFilterChange = (filter: IFilter) => {
    setFilteredInfo(schoolsInfo.filter((item: any) => (
      (checkInFilter(filter.schoolName, item.schoolName) || !lowerCaseArray(filter.schoolName).length)
      && (checkInFilter(filter.email, item.email) || !lowerCaseArray(filter.email).length)
      && (checkInFilter(filter.verification, item.verification) || !lowerCaseArray(filter.verification).length)
      && (checkInFilter(filter.location, item.location) || !lowerCaseArray(filter.location).length)
      && (checkInFilter(filter.sector, item.sector) || !lowerCaseArray(filter.sector).length)
      && (checkInFilter(filter.isHiring, item.isHiring) || !lowerCaseArray(filter.isHiring).length)
    )));
    setCheckboxSelected([]);
  };

  return (
    <Grid sx={{ width: '100%' }}>
      {warning.shown ? <WarningMessage content={warning.message} /> : null}
      {success.shown ? <SuccessMessage content={success.message} /> : null}
      {loading.shown ? <LoadingMessage content={loading.message} /> : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Copied to clipboard
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <DataFilter
          filterConfig={filterTextFields}
          onChange={onFilterChange}
        />
      </Box>
      <Grid xs={12}>
        <Typography
          variant="h6"
          sx={{ color: 'primary.main', margin: '14px 8px' }}
        >
          Actions
        </Typography>
        <Button
          variant="outlined"
          disabled={checkboxSelected.length === 0}
          onClick={copyEmailsToClipboard}
          style={{ marginRight: '5px' }}
        >
          Copy Emails
        </Button>
        <Button
          variant="outlined"
          disabled={checkboxSelected.length === 0}
          onClick={handleDeleteDialogOpen}
          style={{ marginRight: '5px' }}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          disabled={checkboxSelected.length === 0}
          onClick={handleBlockDialogOpen}
          style={{ marginRight: '5px' }}
        >
          Block
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={checkboxSelected.length > 0 && checkboxSelected.length < filteredInfo.length}
                  checked={filteredInfo.length > 0 && checkboxSelected.length === filteredInfo.length}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all schools',
                  }}
                />
              </TableCell>
              <TableCell onClick={() => handleSortClick('id')}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  ID
                  {sortColumn === 'id' && (
                    sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                  )}
                  {sortColumn !== 'id' && <SortIcon />}
                </div>
              </TableCell>
              <TableCell>Partner</TableCell>
              <TableCell>Contact Name</TableCell>
              <TableCell>Contact Email</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell>MTSI Hiring</TableCell>
              <TableCell>Year Level</TableCell>
              <TableCell onClick={() => handleSortClick('sentFromSystem')}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  Email Sent From System
                  {sortColumn === 'sentFromSystem' && (
                    sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                  )}
                  {sortColumn !== 'sentFromSystem' && <SortIcon />}
                </div>
              </TableCell>
              <TableCell>Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${row.id}`;
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onClick={(event) => onCheckboxClick(event, row)}
                    />
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.schoolName}</TableCell>
                  <TableCell>{row.contactName}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.webAddress}</TableCell>
                  <TableCell>{row.location || 'No Location'}</TableCell>
                  <TableCell>{row.sector}</TableCell>
                  <TableCell>{row.isHiring}</TableCell>
                  <TableCell>{matchYear(row.yearLevels)}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{row.verification}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 50]}
        component="div"
        count={filteredInfo.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you wish to proceed?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the selected partners?
            <ul>
              {checkboxSelected.map(((selected) => <li>{`${selected.schoolName} - ${selected.email}`}</li>))}
            </ul>
            This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={() => handleDeleteClick(checkboxSelected.map((selected) => selected.id))} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={blockDialogOpen}
        onClose={handleBlockDialogClose}
        aria-labelledby="block-dialog-title"
        aria-describedby="block-dialog-description"
      >
        <DialogTitle id="block-dialog-title">
          Are you sure you wish to proceed?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="block-dialog-description">
            Are you sure you want to block the selected partners?
            <ul>
              {checkboxSelected.map(((selected) => <li>{`${selected.schoolName} - ${selected.email}`}</li>))}
            </ul>
            This will delete their profiles, and prevent them from creating one again.
            This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBlockDialogClose}>Cancel</Button>
          <Button onClick={() => handleBlockClick(checkboxSelected.map((selected) => selected.id))} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default SchoolTable;
