import { Buffer } from 'buffer';

const getRolesFromJwt = (accessToken: string) => {
  if (accessToken !== undefined) {
    const tokenDecodablePart = accessToken === null ? '' : accessToken.split('.')[1];
    const decoded = JSON.parse(Buffer.from(tokenDecodablePart, 'base64').toString());
    const { authorities } = decoded;
    const roles: string[] = authorities.map((authoritiesObj: any) => Object.values(authoritiesObj).toString());
    return roles;
  }
  return [];
};
export default getRolesFromJwt;
