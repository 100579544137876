import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { AppBar, Button, Grid, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import Copyright from 'src/components/Copyright/Copyright';
import MenuIcon from '@mui/icons-material/Menu';

const pages = ['home', 'team', 'about', 'resources', 'contact'];

type Props = {
  children?: JSX.Element;
  disableHeader?: Boolean;
  disableCopyright?: Boolean;
};

function Landing({ children, disableHeader, disableCopyright }: Props) {
  const navigate = useNavigate();
  const [anchorSignUp, setAnchorSignUp] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorSignUp);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSignUp(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorSignUp(null);
  };

  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorMenu);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenu(null);
  };

  return (
    <Grid container className="landing">
      <Grid item xs={12} sx={{ position: 'sticky', top: '0', zIndex: '2' }}>
        <AppBar position="static" style={{ boxShadow: 'none', padding: '0 24px' }}>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <Typography className="menu_icon">
                <Button
                  id="basic-button"
                  aria-controls={menuOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? 'true' : undefined}
                  onClick={handleMenuClick}
                  style={{ color: 'white' }}
                >
                  <MenuIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorMenu}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      sx={{ textTransform: 'uppercase' }}
                      onClick={() => {
                        navigate(`/${page}`);
                        handleMenuClose();
                      }}
                    >
                      {page}
                    </MenuItem>
                  ))}
                </Menu>
              </Typography>
              <Typography className="menu_buttons">
                {pages.map((page) => (
                  <Button
                    onClick={() => navigate(`/${page}`)}
                    style={{ color: 'white', marginRight: '20px' }}
                  >
                    {page}
                  </Button>
                ))}
              </Typography>
              <Typography style={{ position: 'absolute', right: '0' }}>
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  style={{ color: 'white' }}
                >
                  Sign Up
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorSignUp}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => navigate('/register-school')}>Partner</MenuItem>
                  <MenuItem onClick={() => navigate('/register-student')}>Student</MenuItem>
                </Menu>
                <Button
                  onClick={() => navigate('/login')}
                  style={{ color: 'white' }}
                >
                  Sign In
                </Button>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ margin: `0 ${disableHeader ? '0' : '24px'}`, width: 'inherit' }}>
          {!disableHeader && (
            <Grid container xs={12} sx={{ height: '180px' }}>
              <Typography variant="body2" className="header_logo" sx={{ width: '140px' }}>
                <img
                  alt="UniConnectED logo"
                  src="/images/uniconnected_logo.svg"
                />
              </Typography>
              <div>
                <h1 className="landing_header beta" style={{ color: 'black', fontSize: '40px', marginTop: '65px' }}>
                  UniConnectED
                </h1>
              </div>
            </Grid>
          )}
          { children }
        </Grid>
      </Grid>
      {!disableCopyright && (
        <Copyright sx={{ mt: 5, mb: 5 }} />
      )}
    </Grid>
  );
}

export default Landing;
