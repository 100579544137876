import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, Grid, Link, Snackbar } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import { UoMBlue } from 'src/app/color';

function Home() {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = React.useState(true);

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: '3em' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          icon={<CampaignIcon fontSize="inherit" />}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          We are now expanding to invite other industry and enterprise partners.
          Please&nbsp;
          <Link color="inherit" href="/contact">contact us</Link>
          &nbsp;if you are interested in being part of UniConnectED!
        </Alert>
      </Snackbar>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: 'url(images/home_banner.png)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '400px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundColor: UoMBlue,
            flexGrow: 1,
            display: 'flex',
            position: 'absolute',
            bottom: '0',
            margin: '0 15%',
            width: '70%',
          }}
        >
          <Grid container sx={{ minWidth: '100%' }}>
            <Grid item xs={12}>
              <h3 style={{ color: 'white', margin: '0 14px' }}>
                Advancing Connections | Exceptional Talent
              </h3>
            </Grid>
            <Grid item xs={12}>
              <h4 style={{ color: 'white', margin: '14px' }}>
                Extend your workforce through exclusive access to the exceptional talent at the University of Melbourne
              </h4>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12}>
        <h1 className="home_header beta" style={{ color: 'black', fontSize: '60px', margin: '40px auto', width: 'fit-content' }}>
          UniConnectED
        </h1>
      </Grid>
      <Grid xs={12}>
        <Grid item xs={12} sm={8} md={6} sx={{ margin: '0 auto' }}>
          <p style={{ margin: 0, lineHeight: '2.2em', textAlign: 'center' }}>
            Connecting University of Melbourne students with Industry Partners for part time, casual and graduate employment.
            UniConnectED is an online platform that brings together incredible talent with exceptional opportunities.
            Whether you are a student looking for work or an organisation seeking talent for a casual or part-time role, graduate opportunities or full time employment,
            UniConnectED brings you together.
          </p>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={10} md={6} sx={{ margin: '24px auto' }}>
        <Grid item xs={6}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundImage: 'url(images/home_student_logo.png)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '10em',
              width: '10em',
              position: 'relative',
              margin: '0 auto',
              borderRadius: '5em',
              border: `solid 2px ${UoMBlue}`,
            }}
          />
          <Grid item xs={12}>
            <h2 style={{ color: 'black', margin: '20px auto', width: 'fit-content' }}>Students</h2>
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Looking for work?</h3>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: '90%', margin: '0 auto', lineHeight: '2.2em', textAlign: 'center' }}>
              Create a profile and let employers contact you for part time and casual work or graduate roles.
              <ul style={{ display: 'grid', justifyContent: 'center', textAlign: 'left' }}>
                <li>Meaningful work aligned with your studies</li>
                <li>Build your professional skills and experience</li>
                <li>Expand your network</li>
                <li>Add relevant experience to your CV</li>
              </ul>
              <Button variant="contained" onClick={() => navigate('/register-student')}>Register Here</Button>
            </p>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundImage: 'url(images/home_partners_logo.png)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '10em',
              width: '10em',
              position: 'relative',
              margin: '0 auto',
              borderRadius: '5em',
              border: `solid 2px ${UoMBlue}`,
            }}
          />
          <Grid item xs={12}>
            <h2 style={{ color: 'black', margin: '20px auto', width: 'fit-content' }}>Partners</h2>
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ color: 'black', margin: '5% auto', width: 'fit-content' }}>Need staff?</h3>
          </Grid>
          <Grid item xs={12}>
            <p style={{ width: '90%', margin: '0 auto', lineHeight: '2.2em', textAlign: 'center' }}>
              Employ a University of Melbourne student for part time and casual work or graduate roles.
              <ul style={{ display: 'grid', justifyContent: 'center', textAlign: 'left' }}>
                <li>Exclusive access to exceptional talent</li>
                <li>Solve your staffing shortage</li>
                <li>Direct access to students looking for work</li>
                <li>No agency fees</li>
              </ul>
              <Button variant="contained" onClick={() => navigate('/register-school')}>Register Here</Button>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
