import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Link, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import '../../Register/Register.scss';
import { UoMBlue } from 'src/app/color';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import * as Yup from 'yup';
import { resetPasswordStudent } from 'src/utils/studentApi';
import { resetPasswordSchool } from 'src/utils/schoolsApi';
import { Buffer } from 'buffer';
import SuccessMessage from 'src/components/Register/SuccessMessage/SuccessMessage';
import { resetPasswordAdmin } from 'src/utils/adminApi';
import UniConnectEDlogo from '../../Logo/UniConnectEDlogo';
import WarningMessage from '../../Login/components/WarningMessage/WarningMessage';

function ResetPassword(this: any) {
  const navigate = useNavigate();
  if (window.location.search === '') {
    navigate('/');
  }
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });
  const validationSchema = Yup.object({
    password: Yup
      .string()
      .required('Password is required')
      .min(8, 'Password should be of minimum 8 characters length'),
    checkpassword: Yup
      .string()
      .oneOf([Yup.ref('password'), ''], "Passwords don't match!")
      .required('Please repeat your password.'),
  });
  const submitForm = useCallback(async (value: any) => {
    try {
      const param = window.location.search.split('=');
      const token = param[1];
      const tokenDecodablePart = token === null ? '' : token.split('.')[1];
      const { email } = JSON.parse(Buffer.from(tokenDecodablePart, 'base64').toString());
      const path = window.location.pathname.split('/');
      const role = path[1];
      if (role === 'student') {
        setWarning({
          shown: false,
          message: '',
        });
        const response = await resetPasswordStudent(
          email,
          token,
          {
            password: value.password,
          },
        );
        if (response.status === StatusCodes.OK) {
          localStorage.clear();
          setSucces({
            shown: true,
            message: 'Your password is updated.',
          });
          // navigate('/');
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      }
      if (role === 'school') {
        setWarning({
          shown: false,
          message: '',
        });
        const response = await resetPasswordSchool(
          email,
          token,
          {
            password: value.password,
          },
        );
        if (response.status === StatusCodes.OK) {
          localStorage.clear();
          setSucces({
            shown: true,
            message: 'Your password is updated.',
          });
          // navigate('/');
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      }
      if (role === 'admin') {
        setWarning({
          shown: false,
          message: '',
        });
        const response = await resetPasswordAdmin(
          email,
          token,
          {
            password: value.password,
          },
        );
        if (response.status === StatusCodes.OK) {
          localStorage.clear();
          setSucces({
            shown: true,
            message: 'Your password is updated.',
          });
          setTimeout(() => {
            navigate('/admin/login');
          }, 3000);
        }
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status >= StatusCodes.BAD_REQUEST) {
          setWarning({
            shown: true,
            message: 'Please check your link in email, wrong email to reset password.',
          });
        }
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          setWarning({
            shown: true,
            message: 'Invalid token. Please check the forgotten password link in email.',
          });
        }
      } else {
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      password: '',
      checkpassword: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
  });
  return (
    <div className="background" style={{ backgroundColor: UoMBlue, height: '100vh' }}>
      {/* div for school register form, can copy to school profile overview page */}
      <div className="register-form" style={{ height: '100vh' }}>
        <form noValidate className="form" onSubmit={formik.handleSubmit}>
          <div className="logobox">
            <UniConnectEDlogo />
          </div>
          <h1 className="title" style={{ color: UoMBlue }}>Reset Password</h1>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="checkpassword"
            label="Repeat Password"
            type="password"
            id="checkpassword"
            autoComplete="current-password"
            value={formik.values.checkpassword}
            onChange={formik.handleChange}
            error={formik.touched.checkpassword && Boolean(formik.errors.checkpassword)}
            helperText={formik.touched.checkpassword && formik.errors.checkpassword}
          />
          <div style={{ textAlign: 'center' }}>
            {warning.shown ? <WarningMessage content={warning.message} /> : null}
            {success.shown ? <SuccessMessage content={success.message} /> : null}
            <Button
              className="button"
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
